import axios from 'axios'

const API_URL = '/api/aa/'

// Get aa
const getAa = async (id, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + id, config)

   return response.data
}

// Update aa
const updateAa = async (aaData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.put(API_URL, aaData, config)

   return response.data
}

const aaService = { getAa, updateAa }

export default aaService
