import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/auth/authSlice'
import { ReactComponent as EyeOpen } from '../assets/icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../assets/icons/eye-closed.svg'
import vslu from '../assets/icons/VSLU.svg'

function Login() {
   const [formData, setFormData] = useState({
      name: '',
      password: '',
   })

   const [showPass, setShowPass] = useState(false)

   const { name, password } = formData

   const dispatch = useDispatch()
   const navigate = useNavigate()
   const location = useLocation()

   const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

   // useEffect(() => {
   //    if (isError) {
   //       toast.error(message)
   //    }

   //    // Redirect if register
   //    if (isSuccess || user) {
   //       navigate('/')
   //    }

   //    dispatch(reset())
   // }, [isError, isSuccess, user, message, navigate, useDispatch])

   const onChange = (e) => {
      setFormData((prevState) => ({
         ...prevState,
         [e.target.name]: e.target.value,
      }))
   }

   const onSubmit = (e) => {
      e.preventDefault()

      const userData = {
         name,
         password,
      }

      dispatch(login(userData))
         .unwrap()
         .then((user) => {
            // NOTE: by unwrapping the AsyncThunkAction we can navigate the user after
            // getting a good response from our API or catch the AsyncThunkAction
            // rejection to show an error message
            navigate(location?.state?.prevUrl ? location?.state?.prevUrl : '/')
            // test()
         })
         .catch((error) => toast.error(error))
   }

   if (isLoading) {
      return <Spinner />
   }

   return (
      <div className='login-page'>
         <h2 className='page-title-login'>
            <img src={vslu} alt='' />
         </h2>
         <form className='apodeixhForm box-login' onSubmit={onSubmit}>
            <div className='login-form-titlle'>
               <p className='login-form-titlle-top'>Τιμολόγιο</p>
               <p className='login-form-titlle-text'>Σύνδεση με προσωπικό λογαριασμό</p>
            </div>
            <div className='payment-type'>
               <div className='login-input'>
                  <input
                     type='text'
                     className='create-user'
                     id='name'
                     name='name'
                     value={name}
                     onChange={onChange}
                     placeholder='Όνομα Χρήστη'
                     required
                  />
               </div>
            </div>
            <div className='payment-type'>
               <div className='login-input'>
                  <input
                     type={showPass ? 'text' : 'password'}
                     className='create-user'
                     id='password'
                     name='password'
                     value={password}
                     onChange={onChange}
                     placeholder='Κωδικός Χρήστη'
                     required
                  />
                  <p className='show-pass-toggle' onClick={() => setShowPass((prevState) => !prevState)}>
                     {showPass ? (
                        <EyeClosed fill='#21293a' width='16px' height='16px' />
                     ) : (
                        <EyeOpen fill='#21293a' width='16px' height='16px' />
                     )}
                  </p>
               </div>
            </div>
            <div>
               <button className='mg-1-0 btn-submit width-100'>Είσοδος</button>
            </div>
         </form>
      </div>
   )
}

export default Login
