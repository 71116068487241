import axios from 'axios'

const API_URL = '/api/users/'

// Register user
const register = async (userData) => {
   const response = await axios.post(API_URL, userData)

   //    if (response.data) {
   //       localStorage.setItem('user', JSON.stringify(response.data))
   //    }

   return response.data
}

// Login user
const login = async (userData) => {
   const response = await axios.post(API_URL + 'login', userData)

   if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
   }

   return response.data
}

// Login Status
const checkLoginStatus = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + 'status', config)

   return response.data
}

// Logout user
const logout = () => localStorage.removeItem('user')

// Get all users
const getAllUsers = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + '/all', config)

   return response.data
}

// Get single user
const getSingleUser = async (userId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + userId, config)

   return response.data
}

// Delete single user
const deleteUser = async (userId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.delete(API_URL + userId, config)

   return response.data
}

// Update single User
const updateUser = async ({ User, Data }, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.put(API_URL + User, Data, config)

   return response.data
}

const authService = {
   register,
   logout,
   login,
   getAllUsers,
   getSingleUser,
   deleteUser,
   updateUser,
   checkLoginStatus,
}

export default authService
