import { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCustomers } from '../features/customer/customerSlice'
import BackButton from '../components/BackButton'
import { ReactComponent as CustomerPlus } from '../assets/icons/customer-plus.svg'
import MobileCustomersList from '../components/MobileLists/MobileCustomersList'
import * as React from 'react'
import toast from 'react-hot-toast'
import MediumSpinner from '../components/MediumSpinner'

function Customers() {
   const { customers, isLoadingCustomer } = useSelector((state) => state.customers)

   const [query, setQuery] = useState('')

   const dispatch = useDispatch()

   useEffect(() => {
      if (query.length === 0 || query.length > 2) {
         dispatch(getCustomers(query))
            .unwrap()
            .then(() => {})
            .catch(toast.error)
      }
   }, [query])

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='customers-top-btns'>
               <BackButton url='/' />
               <Link to='/customers/new-customer' className='btn-back'>
                  <CustomerPlus fill='#fff' width='18px' height='18px' /> Δημιουργία Νέου Πελάτη
               </Link>
            </div>

            <h2 className='page-title'>Λίστα Πελατών</h2>
            <input
               className='customer-search min-height-24'
               placeholder='Αναζήτηση'
               onChange={(e) => setQuery(e.target.value)}
            />

            {isLoadingCustomer ? <MediumSpinner /> : <MobileCustomersList customers={customers} />}
         </div>
         <Footer />
      </div>
   )
}

export default Customers
