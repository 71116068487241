//import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import Invoices from './pages/Invoices';
import Invoice from './pages/Invoice';
import Apodeixh from './pages/Apodeixh';
import Login from './pages/Login';
import Register from './pages/Register';
import Users from './pages/Users';
import User from './pages/User';
import NewCustomer from './pages/NewCustomer';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Timologio from './pages/Timologio';
import Prosfora from './pages/Prosfora';
import SkroutzOrder from './pages/SkroutzOrder';
import Trypage from './pages/Trypage';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Help from './pages/Help';
import './styles/style.scss';
import SkroutzMarketPlace from './pages/SkroutzMarketPlace';

function App() {
  const Layout = () => {
    return (
      <>
        <Navbar />

        <PrivateRoute>
          <Outlet />
        </PrivateRoute>
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/invoices/:invoiceId',
          element: <Invoice />,
        },
        {
          path: '/invoices',
          element: <Invoices />,
        },
        {
          path: '/apodeixh',
          element: <Apodeixh />,
        },
        {
          path: '/timologio',
          element: <Timologio />,
        },
        {
          path: '/prosfora',
          element: <Prosfora />,
        },
        {
          path: '/users/:userId',
          element: <User />,
        },
        {
          path: '/users',
          element: <Users />,
        },
        {
          path: '/register',
          element: <Register />,
        },
        {
          path: '/customers/:afm',
          element: <Customer />,
        },
        {
          path: '/customers',
          element: <Customers />,
        },
        {
          path: '/customers/new-customer',
          element: <NewCustomer />,
        },
        {
          path: '/about',
          element: <About />,
        },
        {
          path: '/help',
          element: <Help />,
        },
        {
          path: '/skroutz-orders',
          element: <SkroutzMarketPlace />,
        },
        {
          path: '/skroutz-orders/:orderId',
          element: <SkroutzOrder />,
        },
        {
          path: '/tryy',
          element: <Trypage />,
        },

        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
  ]);

  return (
    <div className='main-body'>
      <RouterProvider router={router} />
      <Toaster
        position='top-center'
        toastOptions={{
          className: '',
          style: {
            fontFamily: 'Gotham Greek Book',
          },
        }}
      />
    </div>
  );
}

export default App;
