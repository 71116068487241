import DetailedInvoices from '../components/printedLists/DetailedInvoices'
import InvoicesTable from '../components/printedLists/InvoicesTable'
import InvoicesList from '../components/listComponents/InvoicesList'
import { GrFormClose } from 'react-icons/gr'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ReactToPrint from 'react-to-print'
import { useRef, useState } from 'react'
import { format } from 'date-fns'

function PrintInvoicesDialogs({ invoices, openPopup, openDetailedInvoices, setOpenPopup, setOpenDetailedInvoices }) {
   const componentRef = useRef()
   const componentRef2 = useRef()

   const [displayInvoiceType, setDisplayInvoiceType] = useState('')

   const invoiceType = (e) => setDisplayInvoiceType(e.target.value)

   const now = new Date()
   const [date, setDate] = useState([
      {
         startDate: new Date(now.getFullYear(), now.getMonth(), 1),
         endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
         key: 'selection',
      },
   ])

   const mystyle = {
      size: '26px',
      backgroundColor: 'red',
      fill: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
   }
   return (
      <>
         <Dialog open={openPopup}>
            <DialogTitle>
               <div className='dialog-header'>
                  <span>Εκτύπωση Παραστατικών</span>
                  <GrFormClose size={26} style={mystyle} onClick={() => setOpenPopup(false)} />
               </div>
            </DialogTitle>
            <DialogContent dividers>
               <ReactToPrint
                  documentTitle='invoices-list'
                  trigger={() => (
                     <div>
                        <button className='btn-back'>Εκτύπωση</button>
                     </div>
                  )}
                  content={() => componentRef.current}
               />
               <div className='printed-invoices-container' ref={componentRef}>
                  <h2 className='title'>
                     Παραστατικά από{' '}
                     {`${format(date[0].startDate, 'dd/MM/yyyy')} έως ${format(date[0].endDate, 'dd/MM/yyyy')}`}{' '}
                     <div className='type'>
                        {displayInvoiceType === 'Απόδειξη Λιανικής Πώλησης' && 'Αποδείξεις Λιανικής Πώλησης'}
                        {displayInvoiceType === 'Τιμολόγιο Πώλησης' && 'Τιμολόγια Πώλησης'}
                        {displayInvoiceType === 'Προσφορά' && 'Προσφορές'}
                        {displayInvoiceType === '' && 'Όλα τα Παραστατικά'}
                     </div>
                  </h2>
                  <InvoicesTable invoices={invoices} />
               </div>
            </DialogContent>
         </Dialog>
         <Dialog open={openDetailedInvoices}>
            <div>
               <DialogTitle>
                  <div className='dialog-header'>
                     <span>Εκτύπωση Παραστατικών</span>
                     <GrFormClose size={26} style={mystyle} onClick={() => setOpenDetailedInvoices(false)} />
                  </div>
               </DialogTitle>
               <DialogContent dividers>
                  <ReactToPrint
                     documentTitle='invoices-list'
                     trigger={() => (
                        <div>
                           <button className='btn-back'>Εκτύπωση</button>
                        </div>
                     )}
                     content={() => componentRef2.current}
                  />
                  <div ref={componentRef2}>
                     <DetailedInvoices invoices={invoices} />
                  </div>
               </DialogContent>
            </div>
         </Dialog>
      </>
   )
}

export default PrintInvoicesDialogs
