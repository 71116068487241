import Footer from '../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { getSkroutzOrders } from '../features/skroutz/skroutzSlice'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import SkroutzOrdersList from '../components/listComponents/SkroutzOrdersList'
import Spinner from '../components/Spinner'
import MobileSkroutzOrders from '../components/MobileLists/MobileSkroutzOrders'
import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import { useState } from 'react'
import Stack from '@mui/material/Stack'
import { useSearchParams } from 'react-router-dom'

function SkroutzMarketPlace() {
   //const query = ''
   const [query, setQuery] = useState('')

   const { orders, isLoadingSkroutz } = useSelector((state) => state.skroutz)

   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(getSkroutzOrders(query))
         .unwrap()
         .then(() => {})
         .catch(toast.error)
   }, [query])

   // Pagination
   let [searchParams, setSearchParams] = useSearchParams()
   const [currentPage, setCurrentPage] = useState(
      searchParams?.get('pageNum') ? parseInt(searchParams?.get('pageNum')) : 1
   )

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='flex-center'>
               <h2 className='page-title'>Παραγγελίες Skroutz</h2>
               <div>
                  <input
                     className='customer-search min-height-24'
                     placeholder='Αναζήτη με Αριθμό Παραγγελίας'
                     onChange={(e) => setQuery(e.target.value)}
                  />
               </div>
            </div>
            <div className='marketplace-list'>
               <MobileSkroutzOrders
                  orders={orders}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setSearchParams={setSearchParams}
               />
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default SkroutzMarketPlace
