import { useEffect } from 'react'
import Footer from '../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllUsers, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import { ReactComponent as CustomerPlus } from '../assets/icons/customer-plus.svg'
import UsersList from '../components/MobileLists/UsersList'

function Customers() {
   const { users, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(getAllUsers())
   }, [dispatch])

   useEffect(() => {
      return () => {
         if (isSuccess) {
            dispatch(reset())
         }
      }
   }, [dispatch, isSuccess])

   if (isLoading) {
      return <Spinner />
   }

   return (
      <div className='users-page page-content top-left-corner'>
         <div className='container'>
            <div className='customers-top-btns'>
               <BackButton url='/' />
               <Link to='/register' className='btn-back'>
                  <CustomerPlus fill='#fff' width='18px' height='18px' /> Δημιουργία Νέου Χρήστη
               </Link>
            </div>
            <h2 className='page-title'>Λίστα Χρηστών</h2>
            <UsersList users={users} />
         </div>
         <Footer />
      </div>
   )
}

export default Customers
