import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import invoiceService from './invoiceService'

const initialState = {
   invoices: [],
   montlyInvoices: {},
   montlyInvoicesDynamo: {},
   montlyInvoicesSkroutz: {},
   lastThreeMonthsInvoices: {},
   invoice: {},
   generatedInvoice: {},
   isErrorInvoice: false,
   isSuccessInvoice: false,
   isLoadingInvoice: false,
   isLoadingInvoiceCreation: false,
   messageInvoice: '',
}

// Create new invoice
export const createInvoice = createAsyncThunk('invoice/create', async (invoiceData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.createInvoice(invoiceData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get all invoices
export const getInvoices = createAsyncThunk('invoice/getAll', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getInvoices(query, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get montly invoices
export const getMontlyInvoices = createAsyncThunk('invoice/getMontly', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getMontlyInvoices(query, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get montly invoices for Dynamo
export const getMontlyInvoicesDynamo = createAsyncThunk('invoice/getDynamo', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getMontlyInvoicesDynamo(query, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get montly invoices for Skroutz
export const getMontlyInvoicesSkroutz = createAsyncThunk('invoice/getSkroutz', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getMontlyInvoicesSkroutz(query, token)
   } catch (error) {
      //console.log(error)
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      if (error.response.data.message === 'Not Authorized') {
         localStorage.removeItem('user')
         return thunkAPI.rejectWithValue('Not Authorized')
      }

      return thunkAPI.rejectWithValue(message)
   }
})

// Get invoices for last 3 months
export const getInvoicesForLastThreeMonths = createAsyncThunk('invoice/getLastThreeMonths', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getInvoicesForLastThreeMonths(query, token)
   } catch (error) {
      //console.log(error)
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      if (error.response.data.message === 'Not Authorized') {
         localStorage.removeItem('user')
         return thunkAPI.rejectWithValue('Not Authorized')
      }

      return thunkAPI.rejectWithValue(message)
   }
})

// Get single invoice
export const getInvoice = createAsyncThunk('invoice/getSingle', async (invoiceId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.getInvoice(invoiceId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Update single invoice isVoid
export const updateInvoice = createAsyncThunk('invoice/updateSingle', async (invoiceId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.updateInvoice(invoiceId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Update single invoice isVoid
export const generateInvoice = createAsyncThunk('invoice/generateInvoice', async (order, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await invoiceService.generateInvoice(order, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const invoiceSlice = createSlice({
   name: 'invoice',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createInvoice.pending, (state) => {
            state.isLoadingInvoice = true
         })
         .addCase(createInvoice.fulfilled, (state) => {
            state.isLoadingInvoice = false
            state.isSuccessInvoice = true
         })
         .addCase(createInvoice.rejected, (state, action) => {
            state.isLoadingInvoice = false
            state.isErrorInvoice = true
            state.messageInvoice = action.payload
         })
         .addCase(getInvoices.pending, (state) => {
            state.isLoadingInvoice = true
         })
         .addCase(getInvoices.fulfilled, (state, action) => {
            state.isLoadingInvoice = false
            state.isSuccessAllInvoice = true
            state.invoices = action.payload
         })
         .addCase(getInvoices.rejected, (state, action) => {
            state.isLoadingInvoice = false
            state.isErrorAllInvoice = true
            state.messageInvoice = action.payload
         })
         .addCase(getMontlyInvoices.pending, (state) => {
            state.isLoadingMontlyInvoice = true
         })
         .addCase(getMontlyInvoices.fulfilled, (state, action) => {
            state.isLoadingMontlyInvoice = false
            state.isSuccessMontlyInvoice = true
            state.montlyInvoices = action.payload
         })
         .addCase(getMontlyInvoices.rejected, (state, action) => {
            state.isLoadingInvoice = false
            state.isErrorMontlyInvoice = true
            state.messageMontlyInvoice = action.payload
         })
         .addCase(getMontlyInvoicesDynamo.pending, (state) => {
            state.isLoadingMontlyInvoice = true
         })
         .addCase(getMontlyInvoicesDynamo.fulfilled, (state, action) => {
            state.isLoadingMontlyInvoice = false
            state.montlyInvoicesDynamo = action.payload
         })
         .addCase(getMontlyInvoicesDynamo.rejected, (state, action) => {
            state.isLoadingInvoice = false
         })
         .addCase(getMontlyInvoicesSkroutz.pending, (state) => {
            state.isLoadingMontlyInvoice = true
         })
         .addCase(getMontlyInvoicesSkroutz.fulfilled, (state, action) => {
            state.isLoadingMontlyInvoice = false
            state.montlyInvoicesSkroutz = action.payload
         })
         .addCase(getMontlyInvoicesSkroutz.rejected, (state, action) => {
            state.isLoadingInvoice = false
         })
         .addCase(getInvoicesForLastThreeMonths.pending, (state) => {
            state.isLoadingMontlyInvoice = true
         })
         .addCase(getInvoicesForLastThreeMonths.fulfilled, (state, action) => {
            state.isLoadingMontlyInvoice = false
            state.lastThreeMonthsInvoices = action.payload
         })
         .addCase(getInvoicesForLastThreeMonths.rejected, (state, action) => {
            state.isLoadingInvoice = false
         })
         .addCase(getInvoice.pending, (state) => {
            state.isLoadingInvoice = true
         })
         .addCase(getInvoice.fulfilled, (state, action) => {
            state.isLoadingInvoice = false
            state.isSuccessSingleInvoice = true
            state.invoice = action.payload
         })
         .addCase(getInvoice.rejected, (state, action) => {
            state.isLoadingInvoice = false
            state.isErrorInvoice = true
            state.messageInvoice = action.payload
         })
         .addCase(generateInvoice.pending, (state) => {
            state.isLoadingInvoiceCreation = true
         })
         .addCase(generateInvoice.fulfilled, (state, action) => {
            state.isLoadingInvoiceCreation = false
            state.generatedInvoice = action.payload
         })
         .addCase(generateInvoice.rejected, (state, action) => {
            state.isLoadingInvoiceCreation = false
         })
         .addCase(updateInvoice.pending, (state) => {
            state.isLoadingInvoice = true
         })
         .addCase(updateInvoice.fulfilled, (state, action) => {
            state.isLoadingInvoice = false
            state.isSuccessSingleInvoice = true
            state.invoice = action.payload
         })
         .addCase(updateInvoice.rejected, (state, action) => {
            state.isLoadingInvoice = false
            state.isErrorInvoice = true
            state.messageInvoice = action.payload
         })
   },
})

export const { reset } = invoiceSlice.actions
export default invoiceSlice.reducer
