import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteCustomer } from '../features/customer/customerSlice'
import toast from 'react-hot-toast'

function DeleteButton({ customerId }) {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   // delete Customer
   const onDeleteCustomer = () => {
      dispatch(deleteCustomer(customerId))
         .unwrap()
         .then(() => {
            toast.success('Επιτυχής διαγραφή πελάτη')
            navigate('/customers')
         })
         .catch(toast.error)
   }
   return (
      <button className='mg-1-0 btn-delete' onClick={onDeleteCustomer}>
         Διαγραφή
      </button>
   )
}

export default DeleteButton
