import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customerService from './customerService'

const initialState = {
   customers: [],
   customer: {},
   isLoadingCustomer: false,
}

// Create new customer
export const createCustomer = createAsyncThunk('customer/create', async (customerData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await customerService.createCustomer(customerData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get all customers
export const getCustomers = createAsyncThunk('customer/getAll', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await customerService.getCustomers(query, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get single customer
export const getCustomer = createAsyncThunk('customer/get', async (afm, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await customerService.getCustomer(afm, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Update single customer
export const updateCustomer = createAsyncThunk('customer/update', async ({ Customer, Data }, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await customerService.updateCustomer(Customer, Data, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Delete single customer
export const deleteCustomer = createAsyncThunk('customer/delete', async (customerId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await customerService.deleteCustomer(customerId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const customerSlice = createSlice({
   name: 'customer',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(createCustomer.pending, (state) => {
            state.isLoadingCustomer = true
         })
         .addCase(createCustomer.fulfilled, (state) => {
            state.isLoadingCustomer = false
         })
         .addCase(createCustomer.rejected, (state, action) => {
            state.isLoadingCustomer = false
         })
         .addCase(getCustomers.pending, (state) => {
            state.isLoadingCustomer = true
         })
         .addCase(getCustomers.fulfilled, (state, action) => {
            state.isLoadingCustomer = false
            state.customers = action.payload
         })
         .addCase(getCustomers.rejected, (state, action) => {
            state.isLoadingCustomer = false
         })
         .addCase(getCustomer.pending, (state) => {
            state.isLoadingCustomer = true
         })
         .addCase(getCustomer.fulfilled, (state, action) => {
            state.isLoadingCustomer = false
            state.customer = action.payload
         })
         .addCase(getCustomer.rejected, (state, action) => {
            state.isLoadingCustomer = false
         })
         .addCase(updateCustomer.fulfilled, (state, action) => {
            state.isLoadingCustomer = false
            state.customer = action.payload
         })
         .addCase(deleteCustomer.fulfilled, (state, action) => {
            state.isLoadingCustomer = false
            state.customer = action.payload
         })
   },
})

export const { reset } = customerSlice.actions
export default customerSlice.reducer
