import { useState, useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getInvoices } from '../features/invoice/invoiceSlice';
import BackButton from '../components/BackButton';
import { ReactComponent as Apodeixh } from '../assets/icons/apodeixh.svg';
import { ReactComponent as Timologio } from '../assets/icons/timologio.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { MdPictureAsPdf } from 'react-icons/md';
import MobileInvoicesList from '../components/MobileLists/MobileInvoicesList';
import * as React from 'react';
import * as rdrLocales from 'react-date-range/dist/locale';
import PrintInvoicesDialogs from '../components/PrintInvoicesDialogs';

function Invoices() {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  // Get Invoices
  const { invoices } = useSelector((state) => state.invoice);

  // Handle Open State
  const [openDate, setOpenDate] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDetailedInvoices, setOpenDetailedInvoices] = useState(false);
  const changeState = (popupState) => setOpenDetailedInvoices(popupState);

  // Set initials for search params
  const now = new Date();
  const [query, setQuery] = useState(
    searchParams?.get('query') ? parseInt(searchParams?.get('query')) : ''
  );
  const [currentPage, setCurrentPage] = useState(
    searchParams?.get('pageNum') ? parseInt(searchParams?.get('pageNum')) : 1
  );
  const [date, setDate] = useState([
    searchParams?.get('startDate') || searchParams?.get('endDate')
      ? {
          startDate: new Date(searchParams?.get('startDate')),
          endDate: new Date(searchParams?.get('endDate')),
          key: 'selection',
        }
      : {
          startDate: new Date(now.getFullYear(), now.getMonth()),
          endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
          key: 'selection',
        },
  ]);
  const [displayInvoiceType, setDisplayInvoiceType] = useState(
    searchParams?.get('invType') ? parseInt(searchParams?.get('invType')) : ''
  );

  const [limit, setLimit] = useState(
    searchParams?.get('limit') ? parseInt(searchParams?.get('limit')) : 25
  );

  const invoiceType = (e) => setDisplayInvoiceType(e.target.value);

  useEffect(() => {
    const params = `?query=${query}&pageNum=${currentPage}&startDate=${format(
      date[0]?.startDate,
      'yyyy-MM-dd'
    )}&endDate=${format(
      date[0]?.endDate,
      'yyyy-MM-dd'
    )}&invType=${displayInvoiceType}&limit=${limit}`;
    setSearchParams(params);

    dispatch(getInvoices(params));
    // eslint-disable-next-line
  }, [
    query,
    date[0]?.startDate,
    date[0]?.endDate,
    displayInvoiceType,
    currentPage,
  ]);

  //handle click away listener
  const detailedInvoicesRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!detailedInvoicesRef.current.contains(event.target)) {
        //setOpenDetailedInvoices(false)

        setOpenDate(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div className='page-content top-left-corner'>
      <div className='container'>
        <div className='customers-top-btns'>
          <BackButton url='/' />
          <div className='create-invoices'>
            <Link to='/apodeixh' className='btn-back'>
              <Apodeixh fill='#fff' width='18px' height='18px' /> Δημιουργία
              Απόδειξης
            </Link>
            <Link to='/timologio' className='btn-back'>
              <Timologio fill='#fff' width='18px' height='18px' /> Δημιουργία
              Τιμολογίου
            </Link>
          </div>
        </div>
        <h2 className='page-title'>Λίστα Παραστατικών</h2>
        <div className='box mg-top-15 flex-column-w100'>
          <div className='flex-center'>
            <div>
              <input
                className='customer-search min-height-24'
                placeholder='Αναζήτη με ΜΑΡΚ ή ΑΦΜ'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div
              className='pos-relative calendar-input min-height-24'
              ref={detailedInvoicesRef}
            >
              <Calendar fill='#21293a' width='18px' height='18px' />
              <span
                onClick={() => {
                  setOpenDate(!openDate);
                }}
                className='display-date-range'
              >{`${format(date[0]?.startDate, 'dd/MM/yyyy')} έως ${format(
                date[0]?.endDate,
                'dd/MM/yyyy'
              )}`}</span>
              {openDate && (
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                  className='date'
                  locale={rdrLocales.el}
                />
              )}
            </div>
            <div>
              <select
                defaultValue='all'
                onChange={invoiceType}
                className='min-height-24 height-45'
                id='tropos_plhrwmhs'
              >
                <option value=''>Όλα</option>
                <option value='Απόδειξη Λιανικής Πώλησης'>Αποδείξεις</option>
                <option value='Τιμολόγιο Πώλησης'>Τιμολόγια</option>
                <option value='Προσφορά'>Προσφορές</option>
              </select>
            </div>
          </div>

          <div className='flex-center'>
            <div
              className='print-invoices-btn'
              onClick={() => setOpenPopup(true)}
            >
              <MdPictureAsPdf fill='#21293a' size={20} />
              Εκτύπωση σε πίνακα
            </div>
            <div
              className='print-invoices-btn'
              onClick={() => setOpenDetailedInvoices(true)}
            >
              <MdPictureAsPdf fill='#21293a' size={20} />
              Εκτύπωση Παραστατικών
            </div>
          </div>
        </div>
        <MobileInvoicesList
          invoices={invoices}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
      </div>
      <Footer />
      <PrintInvoicesDialogs
        invoices={invoices}
        openPopup={openPopup}
        openDetailedInvoices={openDetailedInvoices}
        setOpenPopup={setOpenPopup}
        setOpenDetailedInvoices={setOpenDetailedInvoices}
      />
    </div>
  );
}

export default Invoices;
