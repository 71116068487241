import React from 'react'

function NotFound() {
   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <p className='notfound-page-text'>404 - Η σελίδα δεν βρέθηκε</p>
         </div>
      </div>
   )
}

export default NotFound
