import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
   user: user ? user : null,
   users: [],
   singleUser: {},
   getMe: {},
   isLoading: false,
}

// Register new user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
   try {
      return await authService.register(user)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
   try {
      return await authService.login(user)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
   //setTimeout(authService.logout(), 3000)
})

// Login Status
export const checkLoginStatus = createAsyncThunk('auth/checkLoginStatus', async (_, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.checkLoginStatus(token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      if (error.response.data.message === 'Not Authorized') {
         console.log('logout')
         logout()
         return thunkAPI.rejectWithValue('Not Authorized')
      }

      return thunkAPI.rejectWithValue(message)
   }
})

// Logout user
export const logout = createAction('auth/logout', () => {
   authService.logout()

   return {}
})

// Get all users
export const getAllUsers = createAsyncThunk('auth/getAll', async (_, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.getAllUsers(token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get single user
export const getSingleUser = createAsyncThunk('auth/getSingleUser', async (userId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.getSingleUser(userId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Delete single user
export const deleteUser = createAsyncThunk('auth/deleteUser', async (userId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.deleteUser(userId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Update single user
export const updateUser = createAsyncThunk('auth/update', async ({ User, Data }, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.updateUser(User, Data, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      reset: (state) => {
         state.isLoading = false
         state.messageUpdate = ''
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(register.pending, (state) => {
            state.isLoading = true
         })
         .addCase(register.fulfilled, (state, action) => {
            state.isLoading = false
            //state.user = action.payload
         })
         .addCase(register.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(login.pending, (state) => {
            state.isLoading = true
         })
         .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.user = action.payload
         })
         .addCase(login.rejected, (state) => {
            state.isLoading = false
         })
         .addCase(getAllUsers.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getAllUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.users = action.payload
         })
         .addCase(getAllUsers.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(getSingleUser.pending, (state) => {
            state.isLoading = true
         })
         .addCase(getSingleUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.singleUser = action.payload
         })
         .addCase(getSingleUser.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(deleteUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.singleUser = action.payload
         })
         .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.singleUser = action.payload
         })
         .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(checkLoginStatus.rejected, (state) => {
            state.user = null
         })
   },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
