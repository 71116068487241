import axios from 'axios'

const API_URL = '/api/skroutz/'

// Get skroutz single order
const getSkroutzOrder = async (orderId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + orderId, config)

   return response.data
}

// Update Pay on Deliver
const updatePayOnDelivery = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL + "payOnDeliveryOrder",  data, config)

   return response.data
}

// Update Skroutz Invoice Mark
const updateOrderMark = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL + "updateOrderMark",  data, config)

   return response.data
}

// Get all skroutz orders
const getSkroutzOrders = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get('/api/skroutz/skroutz_merchant_order/?q=' + query, config)

   return response.data
}

// Post skroutz order accept
const postSkroutzAccept = async (query, data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post('/api/skroutz/accept/' + query, data, config)

   return response.data
}

// Get skroutz order invoice state
const getInvoiceState = async (orderId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get('/api/skroutz/printedInvoice/' + orderId, config)

   return response.data
}

// Post skroutz invoice
const postSkroutzInvoice = async (invoiceData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post('/api/skroutz/postInvoice', invoiceData, config)

   return response.data
}

const aadeService = {
   getSkroutzOrder,
   getSkroutzOrders,
   postSkroutzAccept,
   getInvoiceState,
   postSkroutzInvoice,
   updatePayOnDelivery,
   updateOrderMark,
}

export default aadeService
