import { useNavigate } from 'react-router'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

function UsersList({ users }) {
   const navigate = useNavigate()

   // Pagination
   const [perPage, setPerPage] = useState(20)
   const [currentPage, setCurrentPage] = useState(1)

   const indexOfLast = currentPage * perPage
   const indexOfFirst = indexOfLast - perPage
   const currentUsers = users?.slice(indexOfFirst, indexOfLast)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(users?.length / perPage); i++) {
      pageNumbers?.push(i)
   }

   const pageChange = (event, value) => {
      setCurrentPage(value)
   }

   return (
      <div className='Users-table'>
         <div className='table-header'>
            <div>Όνομα:</div>
            <div>Email:</div>
            <div>Admin:</div>
            <div></div>
         </div>
         <div className='table-body'>
            {currentUsers?.length > 0 ? (
               currentUsers?.map((user) => (
                  <div className='row' key={user?._id}>
                     <div className=''>
                        <span className='value-title'>Όνομα:</span> {user?.name}
                     </div>
                     <div className=''>
                        <span className='value-title'>Email:</span> {user?.email}
                     </div>
                     <div className=''>
                        <span className='value-title'>Admin:</span> {user?.isAdmin ? 'Ναι' : 'Όχι'}
                     </div>
                     <div className='flex-end'>
                        <button className='table-button' onClick={() => navigate(`/users/${user?._id}`)}>
                           Προβολή
                        </button>
                     </div>
                  </div>
               ))
            ) : (
               <div className='no-customers'>Δεν υπάρχουν πελάτες</div>
            )}
         </div>
         <div className='pagination-container'>
            <select defaultValue='25' onChange={(e) => setPerPage(e.target.value)}>
               <option value={10}>10</option>
               <option value={25}>25</option>
               <option value={50}>50</option>
               <option value={100}>100</option>
            </select>
            <Stack spacing={2} className='pagination-div'>
               <Pagination
                  count={Math.ceil(users.length / perPage)}
                  onChange={pageChange}
                  variant='outlined'
                  shape='rounded'
                  className='pagination-list'
                  page={currentPage}
               />
            </Stack>
         </div>
      </div>
   )
}

export default UsersList
