// recieve a string or number and return 2 decimal number
const fpaValue = (fpa) => {
   if (fpa === '1') {
      return 1.24
   } else if (fpa === '2') {
      return 1.13
   } else if (fpa === '3') {
      return 1.06
   } else if (fpa === '4') {
      return 1.17
   } else if (fpa === '5') {
      return 1.09
   } else if (fpa === '6') {
      return 1.04
   } else if (fpa === '7') {
      return 1
   } else if (fpa === '8') {
      return 1
   } else {
      return 1.24
   }
}

module.exports = {
   fpaValue,
}
