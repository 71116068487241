import axios from 'axios'

const API_URL = '/api/aadeinvoice/'
const API_URL_D = '/api/aadeinvoice/cancelInvoice'

// Post Apodeixh to aade
const postApodeixh = async (invoiceData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL + 'apodeixh', invoiceData, config)

   return response.data
}

// Post timologio to aade
const postTimologio = async (invoiceData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL + 'timologio', invoiceData, config)

   return response.data
}

// Post deletion of invoice
const postAadeDeletion = async (mark, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL_D, mark, config)

   return response.data
}

const aadeService = { postApodeixh, postTimologio, postAadeDeletion }

export default aadeService
