import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import aaService from './aaService'

const initialState = {
   numbers: {},
   isSuccess: false,
   isLoading: false,
   message: '',
}

// Get aa
export const getAa = createAsyncThunk('aa/get', async (aaData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await aaService.getAa(aaData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Update aa
export const updateAa = createAsyncThunk('aa/update', async (aaData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await aaService.updateAa(aaData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const aaSlice = createSlice({
   name: 'aa',
   initialState,
   reducers: {
      reset: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAa.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.numbers = action.payload
         })
         .addCase(getAa.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
         })
         .addCase(updateAa.fulfilled, (state, action) => {
            state.isLoading = false
            state.aa = action.payload
         })
   },
})

export const { reset } = aaSlice.actions
export default aaSlice.reducer
