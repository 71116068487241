import axios from 'axios'

const API_URL = '/api/invoice/'
const API_URL_FILTER = '/api/invoice'

// Create new invoice
const createInvoice = async (invoiceData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL, invoiceData, config)

   return response.data
}

// Get all invoices
const getInvoices = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL_FILTER + query, config)

   return response.data
}

// Get monthly invoices
const getMontlyInvoices = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + 'monthly/?q=' + query, config)

   return response.data
}

// Get monthly invoices Dynamo
const getMontlyInvoicesDynamo = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + 'dynamo/?q=' + query, config)

   return response.data
}

// Get monthly invoices Skroutz
const getMontlyInvoicesSkroutz = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + 'skroutz/?q=' + query, config)

   return response.data
}

// Get invoices for last three months
const getInvoicesForLastThreeMonths = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + 'threeMonths/?q=' + query, config)

   return response.data
}

// Get single invoice
const getInvoice = async (invoiceId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + invoiceId, config)

   return response.data
}

// Update isVoid of single invoice
const updateInvoice = async (invoiceId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.put(API_URL + invoiceId, config)

   console.log('ta dedomena einai: ', response)

   return response.data
}

// Get all invoices
const generateInvoice = async (order, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
         responseType: 'arraybuffer',
      },
   }

   const response = await axios.post(API_URL + 'generatePdf', order, config)

   return response.data
}

const invoiceService = {
   createInvoice,
   getInvoices,
   getInvoice,
   getMontlyInvoices,
   getMontlyInvoicesDynamo,
   getMontlyInvoicesSkroutz,
   updateInvoice,
   getInvoicesForLastThreeMonths,
   generateInvoice,
}

export default invoiceService
