import { useState } from 'react'
import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { createCustomer } from '../features/customer/customerSlice'
import BackButton from '../components/BackButton'
import { getInfo } from '../features/requestAfm/afmSlice'
import SmallSpinner from '../components/SmallSpinner'

function NewCustomer() {
   // Handle form data
   const [formData, setFormData] = useState({
      afm: '',
      name: '',
      profession: '',
      address: '',
      addressNumber: '',
      city: '',
      postalCode: '',
      doy: '',
      email: '',
      phone1: '',
      phone2: '',
   })

   const [reqAfm, setReqAfm] = useState('')

   const { afm, name, profession, address, addressNumber, city, postalCode, doy, email, phone1, phone2 } = formData

   const { isLoadingAfm } = useSelector((state) => state.afm)

   const onChange = (e) => {
      setFormData((prevState) => ({
         ...prevState,
         [e.target.name]: e.target.value,
      }))
   }

   const dispatch = useDispatch()
   const navigate = useNavigate()

   // Get customer info from afm
   const SearchCustomer = (e) => {
      e.preventDefault()

      if (reqAfm.length !== 9) {
         toast.error('Παρακαλώ εισάγετε 9 ψηφία')
         return
      }
      dispatch(getInfo({ reqAfm }))
         .unwrap()
         .then((info) => {
            const { rname, rproffesion, raddress, raddressNumber, rcity, rzip_code, rdoy } = info
            toast.success('Επιτυχής αναζήτηση πελάτη')
            setFormData({
               afm: reqAfm,
               name: rname,
               profession: rproffesion,
               address: raddress,
               addressNumber: raddressNumber,
               city: rcity,
               postalCode: rzip_code,
               doy: rdoy,
            })
         })
         .catch(toast.error)
   }

   // Create new customer
   const onSubmit = (e) => {
      e.preventDefault()

      dispatch(
         createCustomer({ afm, name, profession, address, addressNumber, city, postalCode, doy, email, phone1, phone2 })
      )
         .unwrap()
         .then(() => {
            toast.success('Επιτυχής καταχώρηση πελάτη')
            navigate(`/customers/${afm}`)
            // Reset form data
            setFormData({
               afm: '',
               name: '',
               profession: '',
               address: '',
               addressNumber: '',
               city: '',
               postalCode: '',
               doy: '',
               email: '',
               phone1: '',
               phone2: '',
            })
         })
         .catch(toast.error)
   }

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <BackButton url='/customers' />
            <h2 className='page-title'>Δημιουργία Νέου Χρήστη</h2>
            <div className='box'>
               <div className='new-customer-afm-search'>
                  <form onSubmit={SearchCustomer}>
                     <input
                        type='number'
                        className='create-user'
                        id='reqAfm'
                        name='reqAfm'
                        value={reqAfm || ''}
                        placeholder='Αναζήτηση ΑΦΜ'
                        onChange={(e) => setReqAfm(e.target.value)}
                     />
                     <button className='mg-1-0 btn-submit mg-left-10'>
                        {isLoadingAfm ? (
                           <span className='small-spiner-flex'>
                              Αναζήτηση <SmallSpinner />
                           </span>
                        ) : (
                           'Αναζήτηση'
                        )}
                     </button>
                  </form>
               </div>
            </div>
            <form onSubmit={onSubmit}>
               <div className='box create-customer-form'>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>ΑΦΜ:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='afm'
                        name='afm'
                        value={afm || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Όνομα:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='name'
                        name='name'
                        value={name || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Επάγγελμα:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='profession'
                        name='profession'
                        value={profession || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Διεύθυνση:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='address'
                        name='address'
                        value={address || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Αριθμός Διεύθ:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='addressNumber'
                        name='addressNumber'
                        value={addressNumber || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Πόλη:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='city'
                        name='city'
                        value={city || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Ταχ Κώδικας:</span>
                     <input
                        type='number'
                        className='create-user'
                        id='postalCode'
                        name='postalCode'
                        value={postalCode || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>ΔΟΥ:</span>
                     <input
                        type='text'
                        className='create-user'
                        id='doy'
                        name='doy'
                        value={doy || ''}
                        onChange={onChange}
                        required
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Email:</span>
                     <input
                        type='email'
                        className='create-user'
                        id='email'
                        name='email'
                        value={email || ''}
                        onChange={onChange}
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Τηλέφωνο 1:</span>
                     <input
                        type='number'
                        className='create-user'
                        id='phone1'
                        name='phone1'
                        value={phone1 || ''}
                        onChange={onChange}
                     />
                  </label>
                  <label className='label-flex-input'>
                     <span className='min-width-120'>Τηλέφωνο 2:</span>
                     <input
                        type='number'
                        className='create-user'
                        id='phone2'
                        name='phone2'
                        value={phone2 || ''}
                        onChange={onChange}
                     />
                  </label>
                  <div>
                     <button className='mg-1-0 btn-submit'>Προσθήκη</button>
                  </div>
               </div>
            </form>
         </div>
         <Footer />
      </div>
   )
}

export default NewCustomer
