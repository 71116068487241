import axios from 'axios'

const API_URL = '/api/afm/'

// Get all invoices
const getInfo = async (afmData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL, afmData, config)

   return response.data
}

const afmService = { getInfo }

export default afmService
