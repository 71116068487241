import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import { register, reset } from '../features/auth/authSlice'
import { ReactComponent as EyeOpen } from '../assets/icons/eye-open.svg'
import { ReactComponent as EyeClosed } from '../assets/icons/eye-closed.svg'

function Register() {
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      password: '',
      password2: '',
      isAdmin: 'false',
   })

   const { name, email, password, password2, isAdmin } = formData

   const [showPass, setShowPass] = useState(false)

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { isLoading } = useSelector((state) => state.auth)

   const onChange = (e) => {
      setFormData((prevState) => ({
         ...prevState,
         [e.target.name]: e.target.value,
      }))
   }

   const onSubmit = (e) => {
      e.preventDefault()

      if (formData.isAdmin === 'false') {
         formData.isAdmin = false
      } else {
         formData.isAdmin = true
      }
      console.log(formData)

      if (password !== password2) {
         toast.error('Οι κωδικοί δεν είναι ίδιοι')
      } else {
         const userData = {
            name,
            email,
            password,
            isAdmin,
         }

         dispatch(register(userData))
            .unwrap()
            .then(() => {
               toast.success(`Ο χρήστης ${userData.name} Δημιουργήθηκε με επιτυχία`)
               navigate('/')
            })
            .catch(toast.error)
      }
   }

   if (isLoading) {
      return <Spinner />
   }

   return (
      <div className='page-content top-left-corner register-page'>
         <div className='container'>
            <h2 className='page-title'>Δημιουργια Χρήστη</h2>
            <form className='create-new-user box' onSubmit={onSubmit}>
               <div className='new-user-input'>
                  <input
                     type='text'
                     className='create-user'
                     id='name'
                     name='name'
                     value={name}
                     onChange={onChange}
                     placeholder='Όνομα Χρήστη'
                     required
                  />
               </div>
               <div className='new-user-input'>
                  <input
                     type='email'
                     className='create-user'
                     id='email'
                     name='email'
                     value={email}
                     onChange={onChange}
                     placeholder='Email Χρήστη'
                     required
                  />
               </div>
               <div className='new-user-input'>
                  <input
                     type={showPass ? 'text' : 'password'}
                     className='create-user'
                     id='password'
                     name='password'
                     value={password}
                     onChange={onChange}
                     placeholder='Κωδικός Χρήστη'
                     required
                  />
               </div>
               <div className='new-user-input'>
                  <input
                     type={showPass ? 'text' : 'password'}
                     className='create-user'
                     id='password2'
                     name='password2'
                     value={password2}
                     onChange={onChange}
                     placeholder='Επαλήθευση κωδικού'
                     required
                  />
                  <p className='show-pass-toggle' onClick={() => setShowPass((prevState) => !prevState)}>
                     {showPass ? (
                        <EyeClosed fill='#21293a' width='16px' height='16px' />
                     ) : (
                        <EyeOpen fill='#21293a' width='16px' height='16px' />
                     )}
                  </p>
               </div>
               <div className='new-user-input'>
                  <p className='isAdminP'>Διαχειριστής</p>
                  <select
                     className='create-user'
                     id='isAdmin'
                     name='isAdmin'
                     value={isAdmin}
                     onChange={onChange}
                     placeholder='Διαχειριστής'
                  >
                     <option value={true}>Ναι</option>
                     <option value={false}>'Οχι</option>
                  </select>
               </div>
               <div>
                  <button className='mg-1-0 btn-submit'>Προσθήκη</button>
               </div>
            </form>
         </div>
         <Footer />
      </div>
   )
}

export default Register
