import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

function SmallSpinner() {
   return (
      <div>
         <CircularProgress color='primary' size='15px' />
      </div>
   )
}

export default SmallSpinner
