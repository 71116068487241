import { useEffect, useRef } from 'react';
import * as React from 'react';
import Footer from '../components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getInvoice,
  updateInvoice,
  reset,
  generateInvoice,
} from '../features/invoice/invoiceSlice';
import { postAadeDeletion, resetAade } from '../features/aade/aadeSlice';
import Spinner from '../components/Spinner';
import toast from 'react-hot-toast';
import { ReactComponent as Logo } from '../assets/icons/logo2.svg';
import SingleInvoiceItem from '../components/SingleInvoiceItem';
import { ReactComponent as Parastatika } from '../assets/icons/parastatika.svg';
import AadeLogo from '../assets/icons/AADE_logo.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { twoDecimals } from '../utils/format';
import { displayPaymentType } from '../utils/payment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function Invoice() {
  const {
    invoice,
    isLoadingInvoice,
    isSuccessInvoice,
    isErrorInvoice,
    messageInvoice,
    isLoadingInvoiceCreation,
  } = useSelector((state) => state.invoice);

  const navigate = useNavigate();

  const { deletionCode, isSuccessAadeDelete } = useSelector(
    (state) => state.aade
  );

  const dispatch = useDispatch();

  const { invoiceId } = useParams();

  const checkCountIn = (product_count_in) => {
    switch (product_count_in) {
      case '1':
        return 'Τμχ';
      case '2':
        return 'Κιλά';
      case '3':
        return 'Λίτρα';
      case '4':
        return 'Μέτρα';
      case '5':
        return 'Τετρ Μ';
      case '6':
        return 'Κυβ Μ';
      case '7':
        return 'Τμχ-Λοιπά';
      default:
        return null;
    }
  };

  const checkPercentage = (product_tax_percentage) => {
    switch (product_tax_percentage) {
      case 1:
        return '24%';
      case 2:
        return '13%';
      case 3:
        return '6%';
      case 4:
        return '17%';
      case 5:
        return '9%';
      case 6:
        return '4%';
      case 7:
        return '0%';
      case 8:
        return 'Χωρίς ΦΠΑ';
      default:
        return null;
    }
  };

  const checkPercentageNum = (product_tax_percentage) => {
    switch (product_tax_percentage) {
      case 1:
        return 1.24;
      case 2:
        return 1.13;
      case 3:
        return 1.06;
      case 4:
        return 1.17;
      case 5:
        return 1.09;
      case 6:
        return 1.04;
      case 7:
        return 1;
      case 8:
        return 1;
      default:
        return null;
    }
  };

  const {
    type,
    series,
    aa,
    date,
    mark,
    qrUrl,
    paymentType,
    totalPriceNet,
    totalPriceVat,
    totalPrice,
    isVoid,
    _id,
    customer,
  } = invoice;

  const componentRef = useRef();

  //const qrtext = `Mark = ${mark} AA= ${aa} Date=${date}`

  const downloadInv = () => {
    const order = {
      aa: invoice.aa,
      customerAFM: invoice.customerAFM,
      customerName: invoice.customerName,
      customerProfession: invoice.customerProfession,
      customerAddress: invoice.customerAddress,
      date: invoice.date,
      mark: invoice.mark,
      qrUrl: invoice.qrUrl,
      paymentType: invoice.paymentType,
      products: invoice.products,
      series: invoice.series,
      totalPrice: invoice.totalPrice,
      totalPriceNet: invoice.totalPriceNet,
      totalPriceVat: invoice.totalPriceVat,
      type: invoice.type,
      isVoid: invoice.isVoid,
    };
    dispatch(generateInvoice(order))
      .unwrap()
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.invoice_file.data).buffer])
        );
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${mark}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    if (isErrorInvoice) {
      toast.error(messageInvoice);
    }

    dispatch(getInvoice(invoiceId));

    if (isSuccessInvoice) {
      dispatch(reset());
    }

    if (isSuccessAadeDelete) {
      if (deletionCode === 'Success') {
        toast.success('Το παραστατικό ακυρώθηκε επιτυχώς');
        dispatch(updateInvoice(_id));
        dispatch(resetAade());
      }
    }
  }, [
    isErrorInvoice,
    messageInvoice,
    invoiceId,
    isSuccessInvoice,
    isSuccessAadeDelete,
    _id,
    deletionCode,
    dispatch,
  ]);

  //Cancel Invoice Function
  const handleDelete = () => {
    dispatch(postAadeDeletion({ mark }));
    setOpen(false);
  };

  //Cancel invoice dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoadingInvoice) {
    return <Spinner />;
  }

  if (isErrorInvoice) {
    return (
      <div className='page-content top-left-corner'>
        <div className='container'>
          <p className='notfound-page-text'>404 - Το τιμολόγιο δεν βρέθηκε</p>
        </div>
      </div>
    );
  }

  return (
    <div className='page-content top-left-corner'>
      <div className='container invoice-container-flex'>
        <div className='customers-top-btns'>
          <div className='btn-back' onClick={() => navigate(-1)}>
            Πίσω
          </div>
          <div className='btn-back' onClick={downloadInv}>
            <span>
              {isLoadingInvoiceCreation ? (
                <Spinner />
              ) : (
                <Parastatika fill='#fff' width='18px' height='18px' />
              )}
            </span>
            Εκτύπωση
          </div>
        </div>
        <div className='display-invoice box'>
          <div className='invoice-header'>
            <div className='vslu-data'>
              <div className='vslu-info'>
                <p style={{ fontWeight: 500 }}>ΒΑΣΙΛΕΙΟΥ Κ ΓΕΩΡΓΙΟΣ</p>
                <p>Θεσσαλονίκης 81 - Βέροια 59100</p>
                <p>ΑΦΜ: 167816416 - ∆ΟΥ ΒΕΡΟΙΑΣ</p>
                <p>Αριθμός Γ.Ε.ΜΗ.: 139796226000</p>
                <p>Τηλ.: 2331024517, Email: info@vslu.gr</p>
                <p>www.vslu.gr</p>
              </div>
              <div className='logo'>
                <Logo width='85%' />
              </div>
            </div>
            <div className='invoice-client'>
              <div className='invoice'>
                <div className='type'>
                  <div className='mobile-title'>Είδος Παραστατικού</div>
                  <div className='text'>{type}</div>
                </div>
                <div className='details'>
                  <div className='inv-details'>
                    <span className='title-color'>Σειρά: </span>
                    {series}
                  </div>
                  <div className='inv-details'>
                    <span className='title-color'>ΑΑ: </span>
                    {aa}
                  </div>
                  <div className='inv-details'>
                    <span className='title-color'>Ημερομηνία: </span>
                    {date}
                  </div>
                  <div className='inv-details'>
                    <span className='title-color'>Μαρκ: </span>
                    {mark}
                  </div>
                  <div className='inv-details'>
                    <span className='title-color'>Τρόπος Πληρωμής: </span>
                    {displayPaymentType(paymentType)}
                  </div>
                </div>
              </div>
              <div className='client'>
                {invoice.type !== 'Απόδειξη Λιανικής Πώλησης' && (
                  <>
                    <div className='title'>
                      <span className='title-color'>Στοιχεία Πελάτη</span>
                    </div>
                    <div className='body'>
                      <div>
                        <span className='title-color'>Α.Φ.Μ: </span>
                        {invoice?.customerAFM
                          ? invoice?.customerAFM
                          : invoice?.customer?.afm}
                      </div>
                      <div>
                        <span className='title-color'>Διεύθυνση: </span>
                        {invoice?.customerAddress
                          ? invoice?.customerAddress
                          : invoice?.customer?.address}
                      </div>
                      <div>
                        <span className='title-color'>Επωνυμία: </span>
                        {invoice?.customerName
                          ? invoice?.customerName
                          : invoice?.customer?.name}
                      </div>
                      <div>
                        <span className='title-color'>Επάγγελμα: </span>
                        {invoice?.customerProfession
                          ? invoice?.customerProfession
                          : invoice?.customer?.profession}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='invoice-body'>
            <div className='table-headers'>
              <div className=''>
                <span className='title-color'>Κωδ</span>
              </div>
              <div className=''>
                <span className='title-color'>Περιγραφή</span>
              </div>
              <div className=''>
                <span className='title-color'>Ποσότητα</span>
              </div>
              <div className=''>
                <span className='title-color'>Μ.Μ.</span>
              </div>
              <div className=''>
                <span className='title-color'>Τιμή Μον. (€)</span>
              </div>
              <div className=''>
                <span className='title-color'>Φ.Π.Α (%)</span>
              </div>
              <div className=''>
                <span className='title-color'>Τελική αξία (€)</span>
              </div>
            </div>
            <div className='table-body'>
              {invoice.products?.map((product) => (
                <div className='row' key={product.product_code}>
                  <div>
                    <div className='mobile-title'>Κωδικός</div>{' '}
                    {product.product_code}
                  </div>
                  <div>
                    <div className='mobile-title'>Περιγραφή</div>{' '}
                    <div className='desc'>{product.product_name}</div>
                  </div>
                  <div>
                    <div className='mobile-title'>Ποσότητα</div>{' '}
                    {product.product_quantity}
                  </div>
                  <div>
                    <div className='mobile-title'>Μ.Μ</div>{' '}
                    {checkCountIn(product.product_count_in)}
                  </div>
                  <div>
                    <div className='mobile-title'>Τιμή Μον. (€)</div>{' '}
                    {twoDecimals(
                      product.product_price /
                        checkPercentageNum(product.product_tax_percentage)
                    )}
                  </div>
                  <div>
                    <div className='mobile-title'>Φ.Π.Α (%)</div>{' '}
                    {checkPercentage(product.product_tax_percentage)}
                  </div>
                  <div>
                    <div className='mobile-title'>Τελική αξία (€)</div>{' '}
                    {twoDecimals(product.product_price)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='invoice-footer'>
            <div className='aade-logo'>
              <div className='top-aade'>Εκδόθηκε από το web API:</div>
              <div className='bottom-aade'>
                <div className='logo-text'>
                  <img src={AadeLogo} alt='' width={140} height={60} />
                </div>
                <div className='timologio-text'>timologio</div>
              </div>
            </div>
            <div className='invoice-totals'>
              <div>
                Συνολική αξία:
                <span>{twoDecimals(totalPriceNet)}€</span>
              </div>
              <div>
                Συνολικό Φ.Π.Α.:
                <span>{twoDecimals(totalPriceVat)}€</span>
              </div>
              <div>
                Σύνολο:
                <span>{twoDecimals(totalPrice)}€ </span>
              </div>
            </div>
          </div>
          {isVoid && <div className='isVoid'>ΑΚΥΡΟ</div>}
        </div>
        {!isVoid && (
          <div className='delete-invoice-btn' onClick={handleClickOpen}>
            Ακύρωση Παραστατικού
          </div>
        )}
      </div>
      <Footer />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle className='ff-gotham-medium'>
          {'Ακύρωση Παραστατικού'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-slide-description'
            className='ff-gotham-book'
          >
            Είστε σίγουροι οτι θέλετε να ακυρώσετε το παραστατικό;
          </DialogContentText>
        </DialogContent>
        <DialogActions className='cancel-invoice-btns'>
          <button className='outline-btn' onClick={handleClose}>
            Κλείσιμο
          </button>
          <button className='btn-back' onClick={handleDelete}>
            Ακύρωση Παραστατικού
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Invoice;
