import MediumSpinner from './MediumSpinner'
import { twoDecimals } from '../utils/format'

function SingleInvoiceItem({ product, isLoading }) {
   if (isLoading) {
      ;<MediumSpinner />
   }

   const checkCountIn = () => {
      switch (product.product_count_in) {
         case '1':
            return 'Τμχ'
         case '2':
            return 'ml'
         case '3':
            return 'Τετρ'
         default:
            return null
      }
   }

   const checkPercentage = () => {
      switch (product.product_tax_percentage) {
         case 1:
            return '24%'
         case 2:
            return '13%'
         case 3:
            return '6%'
         case 4:
            return '17%'
         case 5:
            return '9%'
         case 6:
            return '4%'
         case 7:
            return '0%'
         case 8:
            return 'Χωρίς ΦΠΑ'
         default:
            return null
      }
   }

   const checkPercentageNum = () => {
      switch (product.product_tax_percentage) {
         case 1:
            return 1.24
         case 2:
            return 1.13
         case 3:
            return 1.06
         case 4:
            return 1.17
         case 5:
            return 1.09
         case 6:
            return 1.04
         case 7:
            return 1
         case 8:
            return 1
         default:
            return null
      }
   }

   return (
      <div className='invoice-table-body'>
         <div className='invoice-product-code'>{product.product_code}</div>
         <div className='invoice-product-name'>{product.product_name}</div>
         <div className='invoice-product-qty'>{product.product_quantity}</div>
         <div className='invoice-product-mm'>{checkCountIn(product.product_count_in)}</div>
         <div className='invoice-product-net-price'>
            {twoDecimals(product.product_price / checkPercentageNum(product.product_tax_percentage))}
         </div>
         <div className='invoice-product-fpa-perc'>{checkPercentage(product.product_tax_percentage)}</div>
         <div className='invoice-product-final-price'>
            {twoDecimals(product.product_quantity * product.product_price)}
         </div>
      </div>
   )
}

export default SingleInvoiceItem
