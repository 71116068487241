import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import aadeService from './aadeService'

const initialState = {
   aadeInfo: '',
   deletionCode: '',
   isLoading: false,
}

// Post aade invoice
export const postApodeixh = createAsyncThunk('aade/invoice11', async (invoiceData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await aadeService.postApodeixh(invoiceData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Post aade invoice
export const postTimologio = createAsyncThunk('aade/invoice1', async (invoiceData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await aadeService.postTimologio(invoiceData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Post invoice deletion
export const postAadeDeletion = createAsyncThunk('aade/deleteInvoice', async (mark, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await aadeService.postAadeDeletion(mark, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const aadeSlice = createSlice({
   name: 'aade',
   initialState,
   reducers: {
      resetAade: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(postApodeixh.pending, (state) => {
            state.isLoading = true
         })
         .addCase(postApodeixh.fulfilled, (state, action) => {
            state.isLoading = false
            state.aadeInfo = action.payload
         })
         .addCase(postApodeixh.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(postTimologio.pending, (state) => {
            state.isLoading = true
         })
         .addCase(postTimologio.fulfilled, (state, action) => {
            state.isLoading = false
            state.aadeInfo = action.payload
         })
         .addCase(postTimologio.rejected, (state, action) => {
            state.isLoading = false
         })
         .addCase(postAadeDeletion.pending, (state) => {
            state.isLoading = true
         })
         .addCase(postAadeDeletion.fulfilled, (state, action) => {
            state.isLoading = false
            state.deletionCode = action.payload
         })
         .addCase(postAadeDeletion.rejected, (state, action) => {
            state.isLoading = false
         })
   },
})

export default aadeSlice.reducer
export const { resetAade } = aadeSlice.actions
