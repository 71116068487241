// recieve a string and return couriers track url 
const courierUrl = (courier) => {
    if (courier === 'Skroutz Last Mile') {
       return "https://www.skroutzlastmile.gr/#"
    } else if (courier === 'ACS') {
       return "https://www.acscourier.net/el/web/greece/track-and-trace?action=getTracking&cid=2%CE%92%CE%9E45143&generalCode="
    } else if (courier === 'Speedex') {
       return "https://www.speedex.gr/isapohi.asp?voucher_code="
    } else if (courier === 'Elta') {
       return "https://www.elta.gr/track?code="
    } else if (courier === 'EasyMail') {
       return "https://www.easymail.gr/web-tracking"
    } else if (courier === 'DHL') {
       return "https://www.dhl.com/gr-el/home/parakolouthisi.html?tracking-id="
    } else if (courier === 'Dpd') {
       return "https://services.dpd.gr/tracking/?shipmentNumber="
    } else if (courier === 'Diakinisis') {
       return "https://pod.diakinisis.gr/tnt.php"
    } else if (courier === 'Delatolas') {
        return "https://docuclass.delatolas.com/tnt_temp.php?id="
     } else if (courier === 'Geniki Taxydromiki') {
        return "https://www.taxydromiki.com/track/"
     } else {
       return "https://www.taxydromiki.com/track/"
    }
 }
 
 module.exports = {
    courierUrl,
 }