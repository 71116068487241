import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import customerReducer from '../features/customer/customerSlice'
import invoiceReducer from '../features/invoice/invoiceSlice'
import aaReducer from '../features/aa/aaSlice'
import afmReducer from '../features/requestAfm/afmSlice'
import aadeReducer from '../features/aade/aadeSlice'
import skroutzReducer from '../features/skroutz/skroutzSlice'

export const store = configureStore({
   reducer: {
      auth: authReducer,
      customers: customerReducer,
      invoice: invoiceReducer,
      aa: aaReducer,
      afm: afmReducer,
      aade: aadeReducer,
      skroutz: skroutzReducer,
   },
})
