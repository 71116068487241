import React from 'react'
import { useState } from 'react'
import CustomerSearch from '../components/Invoice/CustomerSearch'
import ProductInput from '../components/Invoice/ProductInput'
import TotalPreview from '../components/Invoice/TotalPreview'
import { getInfo } from '../features/requestAfm/afmSlice'

function Trypage() {
   const [products, setProducts] = useState([])

   const [customer, setCustomer] = useState({})

   const getCustomer = (data) => {
      setCustomer(data)
   }

   //const { cnamePicked, cidPicked, caddressPicked, ccityPicked, cpostalPicked, cphonePicked, cemailPicked } = customer

   //console.log(cnamePicked, cidPicked, caddressPicked, ccityPicked, cpostalPicked, cphonePicked, cemailPicked)

   const getData = (data) => {
      setProducts(data)
   }

   const handleClick = () => {
      const { cafmPicked, caddressPicked, caddressNumberPicked, cpostalPicked, ccityPicked } = customer
      console.log(cafmPicked, caddressPicked, caddressNumberPicked, cpostalPicked, ccityPicked)
   }

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <CustomerSearch onChange={getCustomer} />
            <ProductInput onChange={getData} />
            <TotalPreview products={products} />
            <button onClick={handleClick}>Υποβολή</button>
         </div>
      </div>
   )
}

export default Trypage
