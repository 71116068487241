import { Navigate, useLocation } from 'react-router-dom'
import { checkLoginStatus } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

const PrivateRoute = ({ children }) => {
   const { user } = useSelector((state) => state.auth)

   const dispatch = useDispatch()
   const location = useLocation()

   useEffect(() => {
      const status = async () => {
         dispatch(checkLoginStatus())
      }

      status()
   })

   if (user) return children

   return <Navigate to='/login' state={{ prevUrl: location.pathname }} />
}

export default PrivateRoute
