import React from 'react'
import Footer from '../components/Footer'

function Help() {
   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='help-section'>
               <p className='help-title-section'>Απόδειξη</p>
               <p className='help-text-section'>
                  Για να απδώσουμε μία νέα απόδειξη απο το μενού στα αριστερά κάνουμε κλικ στο πεδίο 'Έκδοση'. Απο το
                  μενού που θα εμφανιστεί κάνουμε κλικ στο πεδίο 'Απόδειξη'. Η διαδικασία δεν απαιτεί κάτι έξτρα σε άλλη
                  σελίδα. Διαλέγουμε τρόπο πληρωμής και συμπληρώνουμε όλα τα πεδία για κάθε προϊόν που θέλουμε να
                  προσθέσουμε (κωδικό προίόντος, όνομα, Μονάδα μέτρησης, ποσοστό ΦΠΑ, ποσότητα και τελική τιμή με ΦΠΑ).
                  Όταν είμαστε έτοιμοι πατάμε το κουμπί 'Έκδοση'. Εάν όλα πήγαν καλά θα εμφανιστεί μήνυμα πως το
                  τιμολόγιο έχει εκδοθεί σωστά και θα μεταφερθούμε στην σελίδα του τιμολογίου που μπορούμε να το
                  εκτυπώσουμε. Για να εκτυπώσουμε ή αποθηκεύσουμε το παραστατικό κάνουμε κλικ στο κουμπί 'Εκτύπωση'.
               </p>
            </div>
            <div className='help-section'>
               <p className='help-title-section'>Τιμολόγιο</p>
               <p className='help-text-section'>
                  Για να απδώσουμε ένα νέο τιμολόγιο πρώτα θα πρέπει:
                  <li>
                     να ελέγξουμε εάν ο πελάτης είναι περασμένος στο σύστημα. Απο το μενού στα αριστερά κάνουμε κλικ στο
                     κουμπί 'Πελάτες'. Στην σελίδα αυτή εμφανίζονται όλοι οι πελάτες που υπάρχουν στο σύστημα. Απο την
                     αναζήτηση βάζοντας το ακριβές όνομα του πελάτη ή το ΑΦΜ και αφού έχουμε πληκτρολογήσει τουλάχιστον
                     3 απο τα γράμμα του ονόματος του ή 3 ψηφία του ΑΦΜ θα ελέγξουμε εάν υπάρχει. Εάν δεν υπάρχει
                     μπορούμε να τον προσθέσουμε κάνοντας κλικ στο κουμπί 'Δημιουργία νέου πελάτη'. Στην σελίδα
                     δημιουργίας πελάτη μπορούμε να κάνουμε αναζήτη των στοιχείων του μέσω του ΑΦΜ του. Συμπληρώνουμε το
                     ΑΦΜ στο πεδίο 'Αναζήτηση ΑΦΜ'. Εάν η αναζήτη ήταν επιτυχής θα εμφανιστή μήνυμα επιτυχούς αναζήτησης
                     και τα στοιχεία του πελάτη θα εμφανιστουν στα κατάλληλα πεδία. Εάν δεν θέλουμε να κάνουμε αναζήτηση
                     στοιχείων πελάτη μέσω ΑΦΜ μπορούμε να συμπληρώσουμε τα πεδία μόνοι μας.
                  </li>
                  <li>
                     Αφού ο πελάτης υπάρχει στο σύστημα μπορούμε να προχωρήσουμε στην έκδοση του τιμολογίου. Απο το
                     μενού στα αριστερά κάνουμε κλικ στο πεδίο 'Έκδοση'. Απο το μενού που θα εμφανιστεί κάνουμε κλικ στο
                     πεδίο 'Τιμολόγιο'. Στην σελίδα Τιμολογίου διαλέγουμε τον πελάτη που θέλουμε να κόψουμε το
                     τιμολόγιο. Για να γίνει αυτό στην αναζήτηση στο πεδίο 'Αναζήτηση πελάτη' βάζουμε το ακριβές όνομα
                     του πελάτη ή το ΑΦΜ του. Αφού έχουμε πληκτρολογήσει 3 γράμματο του ονόματος ή 3 ψηφία του ΑΦΜ θα
                     μας δωθούν οι αντιστοιχήσεις. Αφού διαλέξουμε τον πελάτη πρέπει να διαλέξουμε τρόπο πληρωμής. Στην
                     συνέχεια συμπληρώνουμε όλα τα πεδία για κάθε προϊόν που θέλουμε να προσθέσουμε (κωδικό προίόντος,
                     όνομα, Μονάδα μέτρησης, ποσοστό ΦΠΑ, ποσότητα και τελική τιμή με ΦΠΑ). Όταν είμαστε έτοιμοι πατάμε
                     το κουμπί 'Έκδοση'. Εάν όλα πήγαν καλά θα εμφανιστεί μήνυμα πως το τιμολόγιο έχει εκδοθεί σωστά και
                     θα μεταφερθούμε στην σελίδα του τιμολογίου που μπορούμε να το εκτυπώσουμε. Για να εκτυπώσουμε ή
                     αποθηκεύσουμε το παραστατικό κάνουμε κλικ στο κουμπί 'Εκτύπωση'.
                  </li>
               </p>
            </div>
            <div className='help-section'>
               <p className='help-title-section'>Παραστατικά</p>
               <p className='help-text-section'>
                  Για να βρούμε ένα παραστατικό απο το μενού αριστερά κάνουμε κλικ στο κουμπί 'Παραστατικά'. Εκεί
                  εμφανίζονται όλα τα παραστατικά. Μπορούμε να κάνουμε αναζήτση με βάση το Μαρκ και το Α/Α. Πατώντας το
                  κουμπί προβολή πηγαίνουμε στην σελίδα του παραστατικού. Για να εκτυπώσουμε ή αποθηκεύσουμε το
                  παραστατικό κάνουμε κλικ στο κουμπί 'Εκτύπωση'.
               </p>
            </div>
            <div className='help-section'>
               <p className='help-title-section'>Πελάτες</p>
               <p className='help-text-section'>
                  Απο το μενού στα αριστερά κάνουμε κλικ στο κουμπί 'Πελάτες'. Στην σελίδα αυτή εμφανίζονται όλοι οι
                  πελάτες που υπάρχουν στο σύστημα. Απο την αναζήτηση βάζοντας το ακριβές όνομα του πελάτη ή το ΑΦΜ και
                  αφού έχουμε πληκτρολογήσει τουλάχιστον 3 απο τα γράμμα του ονόματος του ή 3 ψηφία του ΑΦΜ θα ελέγξουμε
                  εάν υπάρχει. Εάν δεν υπάρχει μπορούμε να τον προσθέσουμε κάνοντας κλικ στο κουμπί 'Δημιουργία νέου
                  πελάτη'. Στην σελίδα δημιουργίας πελάτη μπορούμε να κάνουμε αναζήτη των στοιχείων του μέσω του ΑΦΜ
                  του. Συμπληρώνουμε το ΑΦΜ στο πεδίο 'Αναζήτηση ΑΦΜ'. Εάν η αναζήτη ήταν επιτυχής θα εμφανιστή μήνυμα
                  επιτυχούς αναζήτησης και τα στοιχεία του πελάτη θα εμφανιστουν στα κατάλληλα πεδία. Εάν δεν θέλουμε να
                  κάνουμε αναζήτηση στοιχείων πελάτη μέσω ΑΦΜ μπορούμε να συμπληρώσουμε τα πεδία μόνοι μας..
               </p>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Help
