import React, { useEffect, useState } from 'react';

function AddProducts({ products, setProducts }) {
  //const [products, setProducts] = useState([])

  // Error hadling for products
  const prevIsValid = () => {
    if (products.length === 0) {
      return true;
    }

    const emptyField = products.some(
      (item) =>
        item.product_code === '' ||
        item.product_name === '' ||
        item.product_count_in === '' ||
        item.product_tax_percentage === '' ||
        item.product_quantity === '' ||
        item.product_price === ''
    );

    if (emptyField) {
      products.map((item, index) => {
        const allPrev = [...products];
        if (products[index].product_code === '') {
          allPrev[index].errors.product_code =
            'Ο κωδικός προϊόντος είναι υποχρεωτικός';
        }
        if (products[index].product_name === '') {
          allPrev[index].errors.product_name =
            'Το όνομα προϊόντος είναι υποχρεωτικό';
        }
        if (products[index].product_count_in === '') {
          allPrev[index].errors.product_count_in =
            'Η μονάδα μέτρησης είναι υποχρεωτική';
        }
        if (products[index].product_tax_percentage === '') {
          allPrev[index].errors.product_tax_percentage =
            'Το ΦΠΑ προϊόντος είναι υποχρεωτικό';
        }
        if (products[index].product_quantity === '') {
          allPrev[index].errors.product_quantity =
            'Η ποσότητα είναι υποχρεωτική';
        }
        if (products[index].product_price === '') {
          allPrev[index].errors.product_price = 'Η τιμή είναι υποχρεωτική';
        }

        setProducts(allPrev);

        return;
      });
    }

    return !emptyField;
  };

  // Add new product line
  const addProducts = (e) => {
    e.preventDefault();

    const inputState = {
      product_code: '',
      product_name: '',
      product_count_in: 1,
      product_tax_percentage: 1,
      product_quantity: '',
      product_price: '',
      product_discount: 0,

      errors: {
        product_code: null,
        product_name: null,
        product_count_in: null,
        product_tax_percentage: null,
        product_quantity: null,
        product_price: null,
      },
    };

    if (prevIsValid()) {
      setProducts((prev) => [...prev, inputState]);
    }
  };

  const onChange = (index, event) => {
    event.preventDefault();
    event.persist();

    setProducts((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [event.target.name]: event.target.value,

          errors: {
            ...item.errors,
            [event.target.name]:
              event.target.value.length > 0
                ? null
                : [event.target.value.name] + 'είναι υποχρεωτικό',
          },
        };
      });
    });
  };

  // Remove items from products
  const handleRemoveProduct = (e, index) => {
    e.preventDefault();

    setProducts((prev) => prev.filter((item) => item !== prev[index]));
  };

  return (
    <div className='add-product box overflow-auto'>
      <div className='try-of'></div>
      <button className='mg-tb-1 btn-submit' onClick={addProducts}>
        + Προσθήκη Προϊόντος
      </button>

      <div className='product-table'>
        <div className='product-table-head'>
          <div className='p-id table-head'>
            <span className='table-head-span'>Κωδικός</span>
          </div>
          <div className='p-name table-head'>
            <span className='table-head-span'>Όνομα</span>
          </div>
          <div className='p-unit table-head'>
            <span className='table-head-span'>Μονάδα</span>
          </div>
          <div className='p-tax table-head'>
            <span className='table-head-span'>ΦΠΑ</span>
          </div>
          <div className='p-quantity table-head'>
            <span className='table-head-span'>Ποσότητα</span>
          </div>
          <div className='p-price table-head'>
            <span className='table-head-span'>Τιμή</span>
          </div>
          <div className='p-x'>close</div>
        </div>
        {products.map((item, index) => (
          <div className='product-table-body' key={`item-${index}`}>
            <div className='p-id'>
              <input
                type='number'
                className={
                  item.errors.product_code
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_code'
                placeholder='Κωδικός'
                value={item.product_code}
                onChange={(e) => onChange(index, e)}
              />
              {item.errors.product_code && (
                <div className='invalid-input'>{item.errors.product_code}</div>
              )}
            </div>
            <div className='p-name'>
              <input
                type='text'
                className={
                  item.errors.product_name
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_name'
                placeholder='Όνομα προϊόντος'
                value={item.product_name}
                onChange={(e) => onChange(index, e)}
              />
              {item.errors.product_name && (
                <div className='invalid-input'>{item.errors.product_name}</div>
              )}
            </div>
            <div className='p-unit'>
              <select
                className={
                  item.errors.product_count_in
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_count_in'
                placeholder='Μον. Μετρ.'
                value={item.product_count_in}
                onChange={(e) => onChange(index, e)}
              >
                <option value='1'>Τεμ</option>
                <option value='2'>Κιλά</option>
                <option value='3'>Λίτρα</option>
                <option value='4'>Μέτρα</option>
                <option value='5'>Τετρ Μ</option>
                <option value='6'>Κυβ Μ</option>
                <option value='7'>Τμχ-Λοιπά</option>
              </select>
              {item.errors.product_count_in && (
                <div className='invalid-input'>
                  {item.errors.product_count_in}
                </div>
              )}
            </div>
            <div className='p-tax'>
              <select
                className={
                  item.errors.product_tax_percentage
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_tax_percentage'
                placeholder='Μον. Μετρ.'
                value={item.product_tax_percentage}
                onChange={(e) => onChange(index, e)}
              >
                <option value='1'>24%</option>
                <option value='2'>13%</option>
                <option value='3'>6%</option>
                <option value='4'>17%</option>
                <option value='5'>9%</option>
                <option value='6'>4%</option>
                <option value='7'>0%</option>
                <option value='8'>Χωρίς ΦΠΑ</option>
              </select>
            </div>
            <div className='p-quantity'>
              <input
                type='number'
                className={
                  item.errors.product_quantity
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_quantity'
                placeholder='Ποσότητα'
                value={item.product_quantity}
                onChange={(e) => onChange(index, e)}
              />
              {item.errors.product_quantity && (
                <div className='invalid-input'>
                  {item.errors.product_quantity}
                </div>
              )}
            </div>
            <div className='p-price'>
              <input
                type='number'
                className={
                  item.errors.product_price
                    ? 'product-input is-invalid'
                    : 'product-input'
                }
                name='product_price'
                placeholder='Τιμή'
                value={item.product_price}
                onChange={(e) => onChange(index, e)}
              />
              {item.errors.product_price && (
                <div className='invalid-input'>{item.errors.product_price}</div>
              )}
            </div>
            <div className='p-x'>
              <button
                className='remove-btn'
                onClick={(e) => handleRemoveProduct(e, index)}
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddProducts;
