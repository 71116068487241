import { useState } from 'react'
import toast from 'react-hot-toast'
import Footer from '../components/Footer'
import { postApodeixh } from '../features/aade/aadeSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CalculateTotals from '../components/createInvoice/CalculateTotals'
import AddProducts from '../components/createInvoice/AddProducts'

function Apodeixh() {
   // New values
   const [products, setProducts] = useState([])
   const [paymentType, setPaymentType] = useState(3)
   const [totals, setTotals] = useState({
      net_value: 0,
      vat_value: 0,
      total: 0,
   })

   const { isLoading } = useSelector((state) => state.aade)
   const { user } = useSelector((state) => state.auth)

   const dispatch = useDispatch()
   const navigate = useNavigate()

   // Create and submit the XML to AADE
   const onSubmit = async (e) => {
      e.preventDefault()

      const newArray = products.map(({ errors, ...keepAttrs }) => keepAttrs)

      const apodeixh = {
         products: newArray,
         totalPrice: totals.total,
         totalPriceNet: totals.net_value,
         totalPriceVat: totals.vat_value,
         paymentType,
         type: 'Απόδειξη Λιανικής Πώλησης',
         series: 3,
         userId: user._id,
      }

      // Send XML to AADE
      dispatch(postApodeixh(apodeixh))
         .unwrap()
         .then((response) => {
            navigate(`/invoices/${response}`)
            console.log('success')
         })
         .catch((error) => toast.error(error))
   }

   console.log(products)

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <h2 className='page-title'>Έκδοση Απόδειξης</h2>
            <form onSubmit={onSubmit} className='apodeixhForm'>
               <div className='payment-type box'>
                  <label>
                     Τρόπος πληρωμής
                     <select
                        defaultValue='3'
                        onChange={(e) => setPaymentType(e.target.value)}
                        className='mg-1 '
                        id='tropos_plhrwmhs'
                     >
                        <option value='1'>Επαγ. Λογαριασμός Πληρωμών Ημεδαπής</option>
                        <option value='2'>Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής</option>
                        <option value='3'>Μετρητά</option>
                        <option value='4'>Επιταγή</option>
                        <option value='5'>Επί Πιστώσει</option>
                        <option value='6'>WebBanking</option>
                        <option value='7'>POS / e-POS</option>
                     </select>
                  </label>
               </div>
            </form>
            <AddProducts products={products} setProducts={setProducts} />
            <CalculateTotals products={products} totals={totals} setTotals={setTotals} />
            <div className='btn-submit-container'>
               <button type='submit' className='mg-1-0 btn-submit' onClick={onSubmit} disabled={isLoading}>
                  Έκδοση
               </button>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Apodeixh
