import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import afmService from './afmService'

const initialState = {
   info: '',
   isLoadingAfm: false,
}

// Get afm info
export const getInfo = createAsyncThunk('afm/info', async (afmData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await afmService.getInfo(afmData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const afmSlice = createSlice({
   name: 'afm',
   initialState,
   reducers: {
      resetAfm: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getInfo.pending, (state) => {
            state.isLoadingAfm = true
         })
         .addCase(getInfo.fulfilled, (state, action) => {
            state.isLoadingAfm = false
            state.info = action.payload
         })
         .addCase(getInfo.rejected, (state) => {
            state.isLoadingAfm = false
         })
   },
})

export default afmSlice.reducer
export const { resetAfm } = afmSlice.actions
