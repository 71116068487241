import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import Footer from '../components/Footer'
import { createInvoice, reset } from '../features/invoice/invoiceSlice'
import { getCustomers } from '../features/customer/customerSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAa, updateAa } from '../features/aa/aaSlice'

function Prosfora() {
   const [troposPlhrwmhs, setTroposPlhrwmhs] = useState(3)
   const [posostoFPA, setPosostoFPA] = useState(1)
   const [previewSunolo, setPreviewSunolo] = useState(0)
   const [previewNetValue, setPreviewNetValue] = useState(0)
   const [previewTaxes, setPreviewTaxes] = useState(0)
   const [products, setProducts] = useState([])
   const [statePrice, setStatePrice] = useState(0)
   const [stateQuantity, setStateQuantity] = useState(0)
   const [query, setQuery] = useState('')
   const [showSearch, setShowSearch] = useState(false)
   const [cnamePicked, setCnamePicked] = useState('')
   const [cidPicked, setCidPicked] = useState('')
   const [cafmPicked, setCafmPicked] = useState('')
   const [cdoyPicked, setCdoyPicked] = useState('')
   const [cprofessionPicked, setCprofessionPicked] = useState('')
   const [caddressPicked, setCaddressPicked] = useState('')
   const [caddressNumberPicked, setCaddressNumberPicked] = useState('')
   const [cpostalPicked, setCpostalPicked] = useState('')
   const [ccityPicked, setcityPicked] = useState('')

   let today = new Date()
   let dd = String(today.getDate()).padStart(2, '0')
   let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
   let yyyy = today.getFullYear()
   let timhFPA = 1.24
   let timhFPA2 = 1.24
   let total_invoice_value_preview = 0
   let total_net_value_preview = 0
   let total_tax_value_preview = 0
   let uid = ''
   let mark = ''
   const type = 'Προσφορά'
   let invoiceDetails
   let totalNetValuee
   let totalVatAmountt
   let totalProductValueRow
   let totalProductVatRow
   let sunoloo
   let line

   const current_date = yyyy + '-' + mm + '-' + dd

   const { user } = useSelector((state) => state.auth)
   const { customers } = useSelector((state) => state.customers)
   const { numbers, isError, message } = useSelector((state) => state.aa)
   const { isSuccessInvoice, isErrorInvoice } = useSelector((state) => state.invoice)

   let { aaApodeixh, aaTimologio, aaProsfora } = numbers

   console.log(aaApodeixh)

   const id = '62d4022975f0d7ae71aa926c'

   useEffect(() => {
      dispatch(getAa({ id }))
   }, [isError, message, id])

   const dispatch = useDispatch()
   const navigate = useNavigate()

   useEffect(() => {
      if (products.length !== 0) {
         for (let i = 0; i < products.length; i++) {
            if (products[i].product_quantity !== '' && products[i].product_price !== '') {
               if (products[i].product_tax_percentage === '1') {
                  timhFPA2 = 1.24
               } else if (products[i].product_tax_percentage === '2') {
                  timhFPA2 = 1.13
               } else if (products[i].product_tax_percentage === '3') {
                  timhFPA2 = 1.06
               } else if (products[i].product_tax_percentage === '4') {
                  timhFPA2 = 1.17
               } else if (products[i].product_tax_percentage === '5') {
                  timhFPA2 = 1.09
               } else if (products[i].product_tax_percentage === '6') {
                  timhFPA2 = 1.04
               } else if (products[i].product_tax_percentage === '7') {
                  timhFPA2 = 1
               } else if (products[i].product_tax_percentage === '8') {
                  timhFPA2 = 1
               } else {
                  timhFPA2 = 1.24
               }

               total_invoice_value_preview =
                  total_invoice_value_preview + Number(products[i].product_quantity) * Number(products[i].product_price)
               total_net_value_preview =
                  total_net_value_preview +
                  Number(products[i].product_quantity) * (Number(products[i].product_price) / timhFPA2)
               total_tax_value_preview =
                  total_tax_value_preview + (total_invoice_value_preview - total_net_value_preview)

               setPreviewNetValue(total_net_value_preview.toFixed(2))
               setPreviewTaxes(total_tax_value_preview.toFixed(2))
               setPreviewSunolo(total_invoice_value_preview.toFixed(2))
            }
         }
      } else {
         setPreviewNetValue(0)
         setPreviewTaxes(0)
         setPreviewSunolo(0)
      }
   }, [products])

   const onChangeFPA = (e) => setPosostoFPA(e.target.value)

   const onChange3 = (e) => setTroposPlhrwmhs(e.target.value)

   const prevIsValid = () => {
      if (products.length === 0) {
         return true
      }

      const emptyField = products.some(
         (item) =>
            item.product_code === '' ||
            item.product_name === '' ||
            item.product_count_in === '' ||
            item.product_tax_percentage === '' ||
            item.product_quantity === '' ||
            item.product_price === ''
      )

      if (emptyField) {
         products.map((item, index) => {
            const allPrev = [...products]
            if (products[index].product_code === '') {
               allPrev[index].errors.product_code = 'Ο κωδικός προϊόντος είναι υποχρεωτικός'
            }
            if (products[index].product_name === '') {
               allPrev[index].errors.product_name = 'Το όνομα προϊόντος είναι υποχρεωτικό'
            }
            if (products[index].product_count_in === '') {
               allPrev[index].errors.product_count_in = 'Η μονάδα μέτρησης είναι υποχρεωτική'
            }
            if (products[index].product_tax_percentage === '') {
               allPrev[index].errors.product_tax_percentage = 'Το ΦΠΑ προϊόντος είναι υποχρεωτικό'
            }
            if (products[index].product_quantity === '') {
               allPrev[index].errors.product_quantity = 'Η ποσότητα είναι υποχρεωτική'
            }
            if (products[index].product_price === '') {
               allPrev[index].errors.product_price = 'Η τιμή είναι υποχρεωτική'
            }

            setProducts(allPrev)
         })
      }

      return !emptyField
   }

   const addProducts = (e) => {
      e.preventDefault()

      const inputState = {
         product_code: '',
         product_name: '',
         product_count_in: 1,
         product_tax_percentage: 1,
         product_quantity: '',
         product_price: '',
         product_discount: 0,

         errors: {
            product_code: null,
            product_name: null,
            product_count_in: null,
            product_tax_percentage: null,
            product_quantity: null,
            product_price: null,
         },
      }

      if (prevIsValid()) {
         setProducts((prev) => [...prev, inputState])
      }
   }

   const onChange6 = (index, event) => {
      event.preventDefault()
      event.persist()

      setProducts((prev) => {
         return prev.map((item, i) => {
            if (i !== index) {
               return item
            }

            return {
               ...item,
               [event.target.name]: event.target.value,

               errors: {
                  ...item.errors,
                  [event.target.name]: event.target.value.length > 0 ? null : [event.target.value.name] + 'is required',
               },
            }
         })
      })
      if (event.target.name === 'product_price') {
         setStatePrice(event.target.value)
         console.log(statePrice)
      }
      if (event.target.name === 'product_quantity') {
         setStateQuantity(event.target.value)
         console.log(stateQuantity)
      }
   }

   const handleRemoveProduct = (e, index) => {
      e.preventDefault()

      setProducts((prev) => prev.filter((item) => item !== prev[index]))
   }

   useEffect(() => {
      if (query.length > 2) {
         setShowSearch(true)
         dispatch(getCustomers(query))
         // eslint-disable-next-line
      } else {
         setShowSearch(false)
      }
   }, [query])

   const findSingleuser = (id, name, afm, doy, profession, address) => {
      setCnamePicked(name)
      setCafmPicked(afm)
      setCdoyPicked(doy)
      setCprofessionPicked(profession)
      setCaddressPicked(address)
      setCidPicked(id)
      setQuery('')
   }

   const onSubmit = async (e) => {
      e.preventDefault()

      let invoiceDetails = ''
      let totalNetValue = 0
      let totalVatAmount = 0
      let totalProductValueRow = 0
      let totalProductVatRow = 0
      let sunolo = 0
      let line = 1
      for (let i = 0; i < products.length; i++) {
         if (products[i].product_tax_percentage === '1') {
            timhFPA = 1.24
         } else if (products[i].product_tax_percentage === '2') {
            timhFPA = 1.13
         } else if (products[i].product_tax_percentage === '3') {
            timhFPA = 1.06
         } else if (products[i].product_tax_percentage === '4') {
            timhFPA = 1.17
         } else if (products[i].product_tax_percentage === '5') {
            timhFPA = 1.09
         } else if (products[i].product_tax_percentage === '6') {
            timhFPA = 1.04
         } else if (products[i].product_tax_percentage === '7') {
            timhFPA = 1
         } else if (products[i].product_tax_percentage === '8') {
            timhFPA = 1
         } else {
            timhFPA = 1.24
         }

         totalProductValueRow = ((products[i].product_quantity * products[i].product_price) / timhFPA).toFixed(2)
         totalProductVatRow = (products[i].product_quantity * products[i].product_price - totalProductValueRow).toFixed(
            2
         )
         totalNetValue += Number(totalProductValueRow)
         totalVatAmount += Number(totalProductVatRow)
         sunolo = Number(sunolo) + Number(totalProductValueRow) + Number(totalProductVatRow)
         line++
      }
      totalNetValue = Number(totalNetValue).toFixed(2)
      totalVatAmount = Number(totalVatAmount).toFixed(2)
      sunolo = Number(sunolo).toFixed(2)

      console.log(totalProductValueRow, totalProductVatRow, totalNetValue, totalVatAmount, sunolo)

      uid = 123
      mark = aaProsfora

      const aa = aaProsfora
      const series = '2'
      const date = current_date
      const paymentType = 'Μετρητά'
      const totalPrice = sunolo
      const totalPriceNet = totalNetValue
      const totalPriceVat = totalVatAmount
      const userId = user._id
      const customerId = cidPicked
      const customerAFM = cafmPicked
      const customerProfession = cprofessionPicked
      const customerName = cnamePicked
      const customerAddress = caddressPicked
      const userData = {
         type,
         uid,
         series,
         aa,
         date,
         mark,
         paymentType,
         customerId,
         customerAFM,
         customerProfession,
         customerName,
         customerAddress,
         products,
         totalPrice,
         totalPriceNet,
         totalPriceVat,
         userId,
      }

      console.log(userData)

      dispatch(createInvoice(userData))

      aaProsfora++

      const aaData = { aaApodeixh, aaTimologio, aaProsfora }

      dispatch(updateAa(aaData))

      toast.success('Επιτυχής έκδοση παραστατικού')
   }

   useEffect(() => {
      if (isErrorInvoice) {
         toast.error('Η προσφορά δεν αποθηκεύτηκε')
      }
      if (isSuccessInvoice) {
         dispatch(reset())
         //navigate(`/invoices/${mark}`)
      }
   }, [isErrorInvoice, isSuccessInvoice])

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <h2 className='page-title'>Έκδοση Προσφοράς</h2>
            <div className='customer-search-container box'>
               <input
                  className='customer-search'
                  placeholder='Αναζήτηση Πελάτη'
                  onChange={(e) => setQuery(e.target.value)}
               />
               {showSearch ? (
                  <div className='customer-search-results'>
                     {customers?.map((customer) => (
                        <p
                           className='customer-search-select'
                           key={customer._id}
                           onClick={() =>
                              findSingleuser(
                                 customer._id,
                                 customer.name,
                                 customer.afm,
                                 customer.doy,
                                 customer.profession,
                                 customer.address,
                                 customer.addressNumber,
                                 customer.postalCode,
                                 customer.city
                              )
                           }
                        >
                           {customer.name} / {customer.afm}
                        </p>
                     ))}
                  </div>
               ) : (
                  <></>
               )}
               <div className='picked-customer-info'>
                  <div className='picked-customer-label-info'>
                     <p className='picked-customer-title cp-name'>Όνομα: </p>
                     <p className='picked-customer-data cp-name'>{cnamePicked}</p>
                  </div>
                  <div className='picked-customer-label-info'>
                     <p className='picked-customer-title cp-name'>ΑΦΜ: </p>
                     <p className='picked-customer-data cp-name'>{cafmPicked}</p>
                  </div>
                  <div className='picked-customer-label-info'>
                     <p className='picked-customer-title cp-name'>Δ.Ο.Υ: </p>
                     <p className='picked-customer-data cp-name'>{cdoyPicked}</p>
                  </div>
                  <div className='picked-customer-label-info'>
                     <p className='picked-customer-title cp-name'>Επάγγελμα: </p>
                     <p className='picked-customer-data cp-name'>{cprofessionPicked}</p>
                  </div>
               </div>
            </div>
            <form onSubmit={onSubmit} className='apodeixhForm'>
               <div className='payment-type box'>
                  <label>
                     Τρόπος πληρωμής
                     <select defaultValue='3' onChange={onChange3} className='mg-1 ' id='tropos_plhrwmhs'>
                        <option value='1'>Επαγ. Λογαριασμός Πληρωμών Ημεδαπής</option>
                        <option value='2'>Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής</option>
                        <option value='3'>Μετρητά</option>
                        <option value='4'>Επιταγή</option>
                        <option value='5'>Επί Πιστώσει</option>
                        <option value='6'>WebBanking</option>
                        <option value='7'>POS / e-POS</option>
                     </select>
                  </label>
               </div>
            </form>
            <div className='add-product box overflow-auto'>
               <div className='try-of'></div>
               <button className='mg-tb-1 btn-submit' onClick={addProducts}>
                  + Προσθήκη Προϊόντος
               </button>

               <div className='product-table'>
                  <div className='product-table-head'>
                     <div className='p-id table-head'>
                        <span className='table-head-span'>Κωδικός</span>
                     </div>
                     <div className='p-name table-head'>
                        <span className='table-head-span'>Όνομα</span>
                     </div>
                     <div className='p-unit table-head'>
                        <span className='table-head-span'>Μονάδα</span>
                     </div>
                     <div className='p-tax table-head'>
                        <span className='table-head-span'>ΦΠΑ</span>
                     </div>
                     <div className='p-quantity table-head'>
                        <span className='table-head-span'>Ποσότητα</span>
                     </div>
                     <div className='p-price table-head'>
                        <span className='table-head-span'>Τιμή</span>
                     </div>
                     <div className='p-x'>close</div>
                  </div>
                  {products.map((item, index) => (
                     <div className='product-table-body' key={`item-${index}`}>
                        <div className='p-id'>
                           <input
                              type='number'
                              className={item.errors.product_code ? 'product-input is-invalid' : 'product-input'}
                              name='product_code'
                              placeholder='Κωδικός'
                              value={item.product_code}
                              onChange={(e) => onChange6(index, e)}
                           />
                           {item.errors.product_code && <div className='invalid-input'>{item.errors.product_code}</div>}
                        </div>
                        <div className='p-name'>
                           <input
                              type='text'
                              className={item.errors.product_name ? 'product-input is-invalid' : 'product-input'}
                              name='product_name'
                              placeholder='Όνομα προϊόντος'
                              value={item.product_name}
                              onChange={(e) => onChange6(index, e)}
                           />
                           {item.errors.product_name && <div className='invalid-input'>{item.errors.product_name}</div>}
                        </div>
                        <div className='p-unit'>
                           <select
                              className={item.errors.product_count_in ? 'product-input is-invalid' : 'product-input'}
                              name='product_count_in'
                              placeholder='Μον. Μετρ.'
                              value={item.product_count_in}
                              onChange={(e) => onChange6(index, e)}
                           >
                              <option value='1'>Μετρ</option>
                              <option value='2'>ml</option>
                              <option value='3'>Τετρ</option>
                           </select>
                           {item.errors.product_count_in && (
                              <div className='invalid-input'>{item.errors.product_count_in}</div>
                           )}
                        </div>
                        <div className='p-tax'>
                           <select
                              className={
                                 item.errors.product_tax_percentage ? 'product-input is-invalid' : 'product-input'
                              }
                              name='product_tax_percentage'
                              placeholder='Μον. Μετρ.'
                              value={item.product_tax_percentage}
                              onChange={(e) => onChange6(index, e)}
                           >
                              <option value='1'>24%</option>
                              <option value='2'>13%</option>
                              <option value='3'>6%</option>
                              <option value='4'>17%</option>
                              <option value='5'>9%</option>
                              <option value='6'>4%</option>
                              <option value='7'>0%</option>
                              <option value='8'>Χωρίς ΦΠΑ</option>
                           </select>
                        </div>
                        <div className='p-quantity'>
                           <input
                              type='number'
                              className={item.errors.product_quantity ? 'product-input is-invalid' : 'product-input'}
                              name='product_quantity'
                              placeholder='Ποσότητα'
                              value={item.product_quantity}
                              onChange={(e) => onChange6(index, e)}
                           />
                           {item.errors.product_quantity && (
                              <div className='invalid-input'>{item.errors.product_quantity}</div>
                           )}
                        </div>
                        <div className='p-price'>
                           <input
                              type='number'
                              className={item.errors.product_price ? 'product-input is-invalid' : 'product-input'}
                              name='product_price'
                              placeholder='Τιμή'
                              value={item.product_price}
                              onChange={(e) => onChange6(index, e)}
                           />
                           {item.errors.product_price && (
                              <div className='invalid-input'>{item.errors.product_price}</div>
                           )}
                        </div>
                        <div className='p-x'>
                           <button className='remove-btn' onClick={(e) => handleRemoveProduct(e, index)}>
                              X
                           </button>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
            <div className='products-prices-summary mg-tb-1 box'>
               <p>Καθαρή αξία: {previewNetValue}</p>
               <p>ΦΠΑ: {previewTaxes}</p>
               <p>Σύνολο: {previewSunolo}€</p>
            </div>
            <div className='btn-submit-container'>
               <button type='submit' className='mg-1-0 btn-submit' onClick={onSubmit}>
                  Έκδοση
               </button>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Prosfora
