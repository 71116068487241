import { useEffect, useRef, useState } from 'react'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import {
   getMontlyInvoices,
   getMontlyInvoicesDynamo,
   getMontlyInvoicesSkroutz,
   getInvoicesForLastThreeMonths,
} from '../features/invoice/invoiceSlice'
import { useSelector, useDispatch } from 'react-redux'
import MonthlyTotals from '../components/MonthlyTotals'
import { twoDecimals } from '../utils/format'
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg'
import { MdEuro } from 'react-icons/md'
import { format, getDay } from 'date-fns'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import * as rdrLocales from 'react-date-range/dist/locale'

function Home() {
   const [openDate, setOpenDate] = useState(false)
   const now = new Date()
   const today = new Date()
   const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1
   const year = lastMonth === 0 ? today.getFullYear - 1 : today.getFullYear
   const [date, setDate] = useState([
      {
         startDate: new Date(now.getFullYear(), now.getMonth() - 3),
         endDate: new Date(now.getFullYear(), now.getMonth(), now.getDay() - now.getDay()),
         key: 'selection',
      },
   ])

   const { montlyInvoices, montlyInvoicesDynamo, montlyInvoicesSkroutz } = useSelector((state) => state.invoice)

   const dispatch = useDispatch()

   useEffect(() => {
      const send = `${format(date[0].startDate, 'yyyy-MM-dd')}/${format(date[0].endDate, 'yyyy-MM-dd')}`

      dispatch(getMontlyInvoices(send))

      dispatch(getMontlyInvoicesDynamo(send))

      dispatch(getMontlyInvoicesSkroutz(send))
   }, [date[0].startDate, date[0].endDate])

   // useEffect(() => {
   //    const send = `${format(date[0].startDate, 'yyyy-MM-dd')}/${format(date[0].endDate, 'yyyy-MM-dd')}`
   //    dispatch(getInvoicesForLastThreeMonths(send))
   // }, [date[0].startDate, date[0].endDate])

   //handle click away listener
   const detailedInvoicesRef = useRef()

   useEffect(() => {
      let handler = (event) => {
         if (!detailedInvoicesRef.current.contains(event.target)) {
            //setOpenDetailedInvoices(false)
            setOpenDate(false)
         }
      }

      document.addEventListener('mousedown', handler)

      return () => {
         document.removeEventListener('mousedown', handler)
      }
   })

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <h2 className='page-title'>Πίνακας Ελέγχου</h2>
            <div className='usefullLinks-container'>
               <div className='usefullLink'>
                  <Link to='/apodeixh'>Απόδειξη</Link>
               </div>
               <div className='usefullLink'>
                  <Link to='/timologio'>Τιμολόγιο</Link>
               </div>
               <div className='usefullLink'>
                  <Link to='/invoices'>Παραστατικά</Link>
               </div>
               <div className='usefullLink'>
                  <Link to='/customers/new-customer'>Δημιουργία Πελάτη</Link>
               </div>
            </div>

            <div className='mg-top-30'>
               <div className='pos-relative calendar-input min-height-24 mg-top-15' ref={detailedInvoicesRef}>
                  <Calendar fill='#21293a' width='18px' height='18px' />
                  <span
                     onClick={() => {
                        setOpenDate(!openDate)
                     }}
                     className='display-date-range'
                  >{`${format(date[0].startDate, 'dd/MM/yyyy')} έως ${format(date[0].endDate, 'dd/MM/yyyy')}`}</span>
                  {openDate && (
                     <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDate([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        className='date'
                        locale={rdrLocales.el}
                     />
                  )}
               </div>
               <MonthlyTotals
                  montlyInvoices={montlyInvoices}
                  montlyInvoicesDynamo={montlyInvoicesDynamo}
                  montlyInvoicesSkroutz={montlyInvoicesSkroutz}
               />
            </div>
         </div>

         <Footer />
      </div>
   )
}

export default Home
