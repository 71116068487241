import React, { useEffect, useState } from 'react'
import { MdEuro } from 'react-icons/md'
import { twoDecimals } from '../../utils/format'
import { fpaValue } from '../../utils/fpa'

function CalculateTotals({ products, totals, setTotals }) {
   const { net_value, vat_value, total } = totals

   let timhFPA = 1.24
   let product_net = 0
   let product_vat = 0
   let product_total = 0
   let product_net_all = 0
   let product_vat_all = 0
   let product_total_all = 0

   useEffect(() => {
      products.map((product) => {
         if (product.product_quantity === '' || product.product_price === '') return

         timhFPA = fpaValue(product.product_tax_percentage)

         product_total = twoDecimals(product.product_quantity * product.product_price)
         product_total_all += Number(product_total)

         product_net = twoDecimals(product_total / timhFPA)
         product_net_all += Number(product_net)

         product_vat = twoDecimals(product_total - product_net)
         product_vat_all += Number(product_vat)
      })
      setTotals(() => ({
         net_value: product_net_all,
         vat_value: product_vat_all,
         total: product_total_all,
      }))
   }, [products])

   return (
      <div className='products-prices-summary mg-tb-1 box'>
         <p>Καθαρή αξία: {twoDecimals(net_value)}</p>
         <p>ΦΠΑ: {twoDecimals(vat_value)}</p>
         <p className='flex-center-0'>
            Σύνολο: {twoDecimals(total)}
            <MdEuro />
         </p>
      </div>
   )
}

export default CalculateTotals
