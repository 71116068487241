import { MdEuro } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { twoDecimals } from '../../utils/format'
import { useState } from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { courierUrl } from '../../utils/courierUrl'
import { Link } from 'react-router-dom'

function MobileSkroutzOrders({ orders, currentPage, setCurrentPage, setSearchParams }) {
   const navigate = useNavigate()

   // Pagination
   const [perPage, setPerPage] = useState(20)

   const indexOfLast = currentPage * perPage
   const indexOfFirst = indexOfLast - perPage
   const currentOrders = orders?.slice(indexOfFirst, indexOfLast)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(orders?.length / perPage); i++) {
      pageNumbers?.push(i)
   }

   const pageChange = (event, value) => {
      setCurrentPage(value)
      setSearchParams({ pageNum: value })
   }

   const orderState = (state) => {
      switch (state) {
         case 'open':
            return <span className='skroutz-order-state-list open'>Νέα</span>
         case 'accepted':
            return <span className='skroutz-order-state-list accepted'>Προς αποστολή</span>
         case 'dispatched':
            return <span className='skroutz-order-state-list dispatched'>Παραδόθηκε στην courier</span>
         case 'rejected':
            return <span className='skroutz-order-state-list rejected'>Απορρίφθηκε</span>
         case 'cancelled':
            return <span className='skroutz-order-state-list cancelled'>Ακυρώθηκε</span>
         case 'expired':
            return <span className='skroutz-order-state-list expired'>Έχει Λήξει</span>
         case 'delivered':
            return <span className='skroutz-order-state-list delivered'>Ολοκληρώθηκε</span>
         case 'partially_returned':
            return <span className='skroutz-order-state-list partially_returned'>Επιστράφηκε μερικώς</span>
         case 'returned':
            return <span className='skroutz-order-state-list returned'>Έχει Επιστραφεί</span>
         case 'for_return':
            return <span className='skroutz-order-state-list for_return'>Προς επιστροφή</span>
         default:
            return null
      }
   }

   const printedInvoiceState = (printedInvoice) => {
      //console.log(typeof printedInvoice)
      switch (printedInvoice) {
         case true:
            return <span className='skroutz-order-state-list delivered'>Εκδόθηκε</span>
         case false:
            return <span className='skroutz-order-state-list open-pay'>Εκκρεμεί</span>
         default:
            return null
      }
   }

   return (
      <div>
         <div className='display-skroutzMarketplace-table'>
            <div className='table-header'>
               <div>Κωδικός: </div>
               <div>Κατάσταση:</div>
               <div>Όνομα:</div>
               <div>Τύπος Παρ:</div>
               <div>Courier:</div>
               <div>Ημ/νία:</div>
               <div>Παραστατικό:</div>
               <div>Σύνολο:</div>
               <div></div>
            </div>
            <div className='table-body'>
               {currentOrders?.length > 0 ? (
                  currentOrders?.map((order) => (
                     <div className='table-row' key={order?._id}>
                        <div className='table-value'>
                           <span className='title'>Κωδικός: </span>
                           <div>
                              {order?.code} {order.payOnDelivery && <span className='isOrderPayOnDeliverList'></span>}
                           </div>
                        </div>
                        <div className='table-value'>
                           <span className='title'>Κατάσταση: </span>
                           {orderState(order.state)}
                        </div>
                        <div className='table-value'>
                           <span className='title'>Όνομα: </span>
                           {order?.first_name} {order?.last_name}
                        </div>

                        <div className='table-value'>
                           <span className='title'>Παραστατικό: </span>
                           {order?.invoice ? 'Τιμολόγιο' : 'Απόδειξη'}
                        </div>
                        <div className='table-value'>
                           <span className='title'>Courier: </span>
                           <a
                              href={`${courierUrl(order?.courier)}${order?.courier_tracking_code}`}
                              target='_blank'
                              rel='noreferrer'
                           >
                              {order?.courier}
                           </a>
                        </div>
                        <div className='table-value'>
                           <span className='title'>Ημ/νία: </span>
                           {order?.date.split('T')[0]}
                        </div>
                        <div className='table-value'>
                           <span className='title'>Παραστατικό: </span>
                           {printedInvoiceState(order?.printedInvoice)}
                        </div>
                        <div className='table-value'>
                           <span className='title'>Σύνολο: </span>
                           <span>
                              {twoDecimals(order?.invoiceTotal)} <MdEuro size={14} />
                           </span>
                        </div>
                        <Link className='table-button' to={`/skroutz-orders/${order?.code}`}>
                           Προβολή
                        </Link>
                     </div>
                  ))
               ) : (
                  <div className='no-invoices'>Δεν υπάρχουν παραγγελίες</div>
               )}
            </div>
            <div className='pagination-container'>
               <select defaultValue='25' onChange={(e) => setPerPage(e.target.value)}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
               </select>
               <Stack spacing={2} className='pagination-div'>
                  <Pagination
                     count={Math.ceil(orders.length / perPage)}
                     onChange={pageChange}
                     variant='outlined'
                     shape='rounded'
                     className='pagination-list'
                     page={currentPage}
                  />
               </Stack>
            </div>
         </div>
      </div>
   )
}

export default MobileSkroutzOrders
