import React from 'react'
import AadeLogo from '../../assets/icons/AADE_logo.svg'
import { ReactComponent as Logo } from '../../assets/icons/logo2.svg'
import SingleInvoiceItem from '../SingleInvoiceItem'
import { twoDecimals } from '../../utils/format'

function DetailedInvoices({ invoices }) {
   return (
      <div>
         {invoices.map((invoice) => (
            <div className='invoice-box mg-1-0 invoice-template' key={invoice?.mark}>
               <div className='invoice-max-width'>
                  <div className='logo-and-vslu-info'>
                     <div className='logo-container'>
                        <Logo width='85%' />
                     </div>
                     <div className='vslu-info'>
                        <p style={{ fontWeight: 500 }}>ΒΑΣΙΛΕΙΟΥ Κ ΓΕΩΡΓΙΟΣ</p>
                        <p>Θεσσαλονίκης 81 - Βέροια 59100</p>
                        <p>ΑΦΜ: 167816416 - ∆ΟΥ ΒΕΡΟΙΑΣ</p>
                        <p>Τηλ.: 2331024517, Email: info@vslu.gr</p>
                     </div>
                  </div>
                  <div className='invoice-info-container'>
                     <div className='invoice-type' style={{ fontWeight: 500 }}>
                        {invoice?.type}
                     </div>
                     <div className='invoice-general-info'>
                        <p>Σειρά: {invoice?.series}</p>
                        <p>ΑΑ: {invoice?.aa}</p>
                        <p>Ημερομηνία: {invoice?.date}</p>
                        <p>Μαρκ: {invoice?.mark}</p>
                        <p>Τρόπος πληρωμής: {invoice?.paymentType}</p>
                     </div>
                  </div>
                  <div className='invoice-info-container'>
                     <div className='invoice-type' style={{ fontWeight: 500 }}>
                        Στοιχεία Πελάτη
                     </div>
                     <div className='invoice-general-info'>
                        <p>Α.Φ.Μ: {invoice.customerAFM}</p>
                        <p>Επωνυμία: {invoice?.customerName}</p>
                        <p>Επάγγελμα: {invoice?.customerProfession}</p>
                     </div>
                  </div>
                  <div className='products-container'>
                     <div className='invoice-product-titles'>
                        <div className='invoice-product-code'>Κωδ</div>
                        <div className='invoice-product-name'>Περιγραφή</div>
                        <div className='invoice-product-qty'>Ποσότητα</div>
                        <div className='invoice-product-mm'>Μ.Μ.</div>
                        <div className='invoice-product-net-price'>Τιμή Μον. (€)</div>
                        <div className='invoice-product-fpa-perc'>Φ.Π.Α (%)</div>
                        <div className='invoice-product-final-price'>Τελική αξία (€)</div>
                     </div>
                     {invoice.products?.map((product) => (
                        <SingleInvoiceItem key={product.product_code} product={product} />
                     ))}
                  </div>
               </div>
               <div className='invoice-totals-container'>
                  {/* <TaxAnalysis products={invoice.products} /> css 1303 && 1318 space-between */}
                  <div className='aade-logo'>
                     <div className='top-aade'>Εκδόθηκε από το web API:</div>
                     <div className='bottom-aade'>
                        <div className='logo-text'>
                           {/* <AadeLogo width={50} /> */}
                           <img src={AadeLogo} alt='' width={140} height={60} />
                        </div>
                        <div className='timologio-text'>timologio</div>
                     </div>
                  </div>
                  <div className='invoice-totals-border'>
                     <p>
                        Συνολική αξία:
                        <span className='invoice-totals'>{twoDecimals(invoice?.totalPriceNet)}€</span>
                     </p>
                     <p>
                        Συνολικό Φ.Π.Α.:
                        <span className='invoice-totals'>{twoDecimals(invoice?.totalPriceVat)}€</span>
                     </p>
                     <p>
                        Σύνολο:
                        <span className='invoice-totals'>{twoDecimals(invoice?.totalPrice)}€ </span>
                     </p>
                  </div>
               </div>
               {invoice?.isVoid && <div className='isVoid'>ΑΚΥΡΟ</div>}
            </div>
         ))}
      </div>
   )
}

export default DetailedInvoices
