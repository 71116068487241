import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { ReactComponent as Home } from '../assets/icons/home.svg'
import { ReactComponent as Parastatika } from '../assets/icons/parastatika.svg'
import { ReactComponent as Xrhstes } from '../assets/icons/xrhstes.svg'
import { ReactComponent as Logout } from '../assets/icons/logout.svg'
import { ReactComponent as Customers } from '../assets/icons/customers.svg'
import { ReactComponent as OpenMenu } from '../assets/icons/menu-open.svg'
import { ReactComponent as CloseMenu } from '../assets/icons/menu-close.svg'
import { ReactComponent as MenuDown } from '../assets/icons/menu-down.svg'
import SkroutzLogo from '../assets/icons/skroutz-logo-white.png'
import SkroutzLogo2 from '../assets/icons/skroutz-logo-blue.png'
import VSLU from '../assets/icons/VSLU.svg'

function Navbar() {
   const [toggleMenu, setToggleMenu] = useState(false)
   const { user } = useSelector((state) => state.auth)
   const [isAdmin, setIsAdmin] = useState(false)
   const [showDropdown, setShowDropdown] = useState(false)

   const navigate = useNavigate()
   const location = useLocation()
   const dispatch = useDispatch()

   const pathMatchRoute = (route) => {
      if (route === location.pathname) {
         return true
      }
   }

   useEffect(() => {
      if (user && user.isAdmin) {
         setIsAdmin(true)
      }
   }, [user])

   const onLogout = () => {
      dispatch(logout())
      dispatch(reset())
      navigate('/')
      window.location.reload()
   }

   return (
      <>
         <div
            className={toggleMenu ? 'toggle-Menu fixed' : 'toggle-Menu'}
            onClick={() => setToggleMenu((prevState) => !prevState)}
         >
            {toggleMenu ? (
               <CloseMenu fill='#fff' width='30px' height='30px' />
            ) : (
               <OpenMenu fill='#fff' width='30px' height='30px' />
            )}
         </div>
         <header className={toggleMenu ? 'header-active' : 'header'}>
            <nav className='nav-container'>
               {/* <div className='nav-logo' onClick={() => navigate('/') & setToggleMenu(false)}>
                  ΒΑΣΙΛΕΙΟΥ
               </div> */}
               <div className='nav-svg' onClick={() => navigate('/') & setToggleMenu(false)}>
                  <img src={VSLU} />
               </div>
               <ul className='navbarUl'>
                  <li className='navbarListItem' onClick={() => setToggleMenu(false) & navigate('/')}>
                     <Home fill={pathMatchRoute('/') ? '#1d7dca' : '#fff'} width='18px' height='18px' />
                     <p className={pathMatchRoute('/') ? 'navbarListItemNameActive' : 'navbarListItemName'}>Αρχική</p>
                  </li>
                  <li className='navbarListItem-dropdown' onClick={() => setShowDropdown((prevState) => !prevState)}>
                     <div className='create-invoice'>
                        <MenuDown
                           fill='#fff'
                           width='18px'
                           height='18px'
                           className={showDropdown ? 'dropdown-active' : 'noth'}
                        />
                        <p className='navbarListItemName'>
                           <span>Έκδοση</span>
                        </p>
                     </div>

                     <div className={showDropdown ? 'nav-submenu-visible' : 'nav-submenu-hidden'}>
                        <span
                           onClick={() => navigate('/apodeixh') & setToggleMenu(false)}
                           className={pathMatchRoute('/apodeixh') ? 'navbarListItemNameActive' : 'navbarListItemName'}
                        >
                           Απόδειξη
                        </span>
                        <span
                           onClick={() => navigate('/timologio') & setToggleMenu(false)}
                           className={pathMatchRoute('/timologio') ? 'navbarListItemNameActive' : 'navbarListItemName'}
                        >
                           Τιμολόγιο
                        </span>
                        <span
                           onClick={() => {
                              navigate('/prosfora')
                           }}
                           className={pathMatchRoute('/prosfora') ? 'navbarListItemNameActive' : 'navbarListItemName'}
                        >
                           Προσφορά
                        </span>
                     </div>
                  </li>
                  <li className='navbarListItem' onClick={() => navigate('/invoices') & setToggleMenu(false)}>
                     <Parastatika fill={pathMatchRoute('/invoices') ? '#1d7dca' : '#fff'} width='16px' height='16px' />
                     <p className={pathMatchRoute('/invoices') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                        Παραστατικά
                     </p>
                  </li>
                  <li className='navbarListItem' onClick={() => navigate('/customers') & setToggleMenu(false)}>
                     <Customers fill={pathMatchRoute('/customers') ? '#1d7dca' : '#fff'} width='16px' height='16px' />
                     <p className={pathMatchRoute('/customers') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                        Πελάτες
                     </p>
                  </li>
                  <li className='navbarListItem' onClick={() => navigate('/skroutz-orders') & setToggleMenu(false)}>
                     <img src={pathMatchRoute('/skroutz-orders') ? SkroutzLogo2 : SkroutzLogo} alt='' width={18} />
                     <p
                        className={
                           pathMatchRoute('/skroutz-orders') ? 'navbarListItemNameActive' : 'navbarListItemName'
                        }
                     >
                        Skroutz
                     </p>
                  </li>
                  {isAdmin ? (
                     <li className='navbarListItem' onClick={() => navigate('/users') & setToggleMenu(false)}>
                        <Xrhstes fill={pathMatchRoute('/users') ? '#1d7dca' : '#fff'} width='16px' height='16px' />
                        <p className={pathMatchRoute('/users') ? 'navbarListItemNameActive' : 'navbarListItemName'}>
                           Χρήστες
                        </p>
                     </li>
                  ) : (
                     <></>
                  )}
                  {user ? (
                     <li className='navbarListItem'>
                        <Logout fill='#fff' width='16px' height='16px' />
                        <p className='navbarListItemName' onClick={onLogout}>
                           Αποσύνδεση
                        </p>
                     </li>
                  ) : (
                     ''
                  )}
               </ul>
            </nav>
         </header>
      </>
   )
}

export default Navbar
