import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

function Spinner() {
   return (
      <div className='loading-spinner-container'>
         <CircularProgress color='primary' />
      </div>
   )
}

export default Spinner
