import React from 'react'
import { MdEuro } from 'react-icons/md'
import { twoDecimals } from '../../utils/format'

function InvoicesTable({ invoices }) {
   const totalIncome = invoices.reduce(function (prev, cur) {
      if (cur.isVoid) return prev
      return prev + cur.totalPrice
   }, 0)
   const totalNetValue = invoices.reduce(function (prev, cur) {
      if (cur.isVoid) return prev
      return prev + cur.totalPriceNet
   }, 0)
   const totalVatValue = invoices.reduce(function (prev, cur) {
      if (cur.isVoid) return prev
      return prev + cur.totalPriceVat
   }, 0)
   return (
      <div>
         <div className='single-line header-in'>
            <div className='in-mark'>Μαρκ</div>
            <span className='in-type'>Τύπος</span>
            <span className='in-createdAt'>Ημερομηνία</span>
            <span className='in-series'>Σ</span>
            <span className='in-aa'>ΑΑ</span>
            <span className='in-customerAFM'>ΑΦΜ</span>
            <span className='in-price'>Καθ. Αξ.</span>
            <span className='in-price'>ΦΠΑ</span>
            <span className='in-price2'>Τελική</span>
         </div>
         {invoices.map((invoice) => (
            <div key={invoice.mark}>
               <div className='single-line'>
                  <span className={invoice.isVoid ? 'in-mark canceled-in' : 'in-mark'}>{invoice.mark}</span>
                  <span className='in-type'>{invoice?.type === 'Απόδειξη Λιανικής Πώλησης' ? 'Α.Λ.Π' : 'Τ.Π.'}</span>
                  <span className='in-createdAt'>{invoice?.date}</span>
                  <span className='in-series'>{invoice?.series}</span>
                  <span className='in-aa'>{invoice?.aa}</span>
                  <span className='in-customerAFM'>{invoice?.customerAFM}</span>
                  <span className='in-price'>{invoice?.totalPriceNet}</span>
                  <span className='in-price'>{invoice?.totalPriceVat}</span>
                  <span className='in-price2'>
                     {twoDecimals(invoice?.totalPrice)} <MdEuro />
                  </span>
               </div>
            </div>
         ))}
         <div className='total-prices'>
            <span>
               Καθαρή Αξία: {twoDecimals(totalNetValue)} <MdEuro size={14} />
            </span>
            <span>
               ΦΠΑ: {twoDecimals(totalVatValue)} <MdEuro size={14} />
            </span>
            <span>
               Σύνολο: {twoDecimals(totalIncome)} <MdEuro size={14} />
            </span>
         </div>
      </div>
   )
}

export default InvoicesTable
