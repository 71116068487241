import React, { useEffect, useState } from 'react'
import { MdEuro } from 'react-icons/md'
import { twoDecimals } from '../../utils/format'

function TotalPreview({ products }) {
   const [previewSunolo, setPreviewSunolo] = useState(0)
   const [previewNetValue, setPreviewNetValue] = useState(0)
   const [previewTaxes, setPreviewTaxes] = useState(0)

   let timhFPA2 = 1.24
   let total_invoice_value_preview = 0
   let total_net_value_preview = 0
   let total_tax_value_preview = 0

   useEffect(() => {
      if (products.length !== 0) {
         for (let i = 0; i < products.length; i++) {
            if (products[i].product_quantity !== '' && products[i].product_price !== '') {
               if (products[i].product_tax_percentage === '1') {
                  timhFPA2 = 1.24
               } else if (products[i].product_tax_percentage === '2') {
                  timhFPA2 = 1.13
               } else if (products[i].product_tax_percentage === '3') {
                  timhFPA2 = 1.06
               } else if (products[i].product_tax_percentage === '4') {
                  timhFPA2 = 1.17
               } else if (products[i].product_tax_percentage === '5') {
                  timhFPA2 = 1.09
               } else if (products[i].product_tax_percentage === '6') {
                  timhFPA2 = 1.04
               } else if (products[i].product_tax_percentage === '7') {
                  timhFPA2 = 1
               } else if (products[i].product_tax_percentage === '8') {
                  timhFPA2 = 1
               } else {
                  timhFPA2 = 1.24
               }

               total_invoice_value_preview =
                  total_invoice_value_preview + Number(products[i].product_quantity) * Number(products[i].product_price)
               total_net_value_preview =
                  total_net_value_preview +
                  Number(products[i].product_quantity) * (Number(products[i].product_price) / timhFPA2)
               total_tax_value_preview =
                  total_tax_value_preview + (total_invoice_value_preview - total_net_value_preview)

               // Set total Net value for display purposes
               setPreviewNetValue(total_net_value_preview)
               // Set total Vat value for display purposes
               setPreviewTaxes(total_tax_value_preview)
               // Set total value for display purposes
               setPreviewSunolo(total_invoice_value_preview)
            }
         }
      } else {
         setPreviewNetValue(0)
         setPreviewTaxes(0)
         setPreviewSunolo(0)
      }
   }, [products])
   return (
      <div className='products-prices-summary mg-tb-1 box'>
         <p>Καθαρή αξία: {twoDecimals(previewNetValue)}</p>
         <p>ΦΠΑ: {twoDecimals(previewTaxes)}</p>
         <p className='flex-center-0'>
            Σύνολο: {twoDecimals(previewSunolo)}
            <MdEuro />
         </p>
      </div>
   )
}

export default TotalPreview
