import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { getCustomers } from '../../features/customer/customerSlice'

function CustomerSearch(props) {
   const [query, setQuery] = useState('')
   const [showSearch, setShowSearch] = useState(false)
   const [cnamePicked, setCnamePicked] = useState('')
   const [cidPicked, setCidPicked] = useState('')
   const [cafmPicked, setCafmPicked] = useState('')
   const [cdoyPicked, setCdoyPicked] = useState('')
   const [cprofessionPicked, setCprofessionPicked] = useState('')
   const [caddressPicked, setCaddressPicked] = useState('')
   const [caddressNumberPicked, setCaddressNumberPicked] = useState('')
   const [cpostalPicked, setCpostalPicked] = useState('')
   const [ccityPicked, setcityPicked] = useState('')

   const dispatch = useDispatch()

   const location = useLocation()
   const { search } = location

   const { customers } = useSelector((state) => state.customers)

   useEffect(() => {
      if (search) {
         const searchAfm = search.split('=')
         setQuery(searchAfm[1])
      }
   }, [search])

   useEffect(() => {
      if (query.length > 2) {
         setShowSearch(true)
         dispatch(getCustomers(query))
         // eslint-disable-next-line
      } else {
         setShowSearch(false)
      }
   }, [query])

   const findSingleuser = (id, name, afm, doy, profession, address, addressNumber, postalCode, city) => {
      setCnamePicked(name)
      setCafmPicked(afm)
      setCdoyPicked(doy)
      setCprofessionPicked(profession)
      setCidPicked(id)
      setCaddressPicked(address)
      setCaddressNumberPicked(addressNumber)
      setCpostalPicked(postalCode)
      setcityPicked(city)
      setQuery('')
   }

   const customerInfo = {
      cnamePicked,
      cidPicked,
      cafmPicked,
      cdoyPicked,
      cprofessionPicked,
      caddressPicked,
      caddressNumberPicked,
      cpostalPicked,
      ccityPicked,
   }

   useEffect(() => {
      props.onChange(customerInfo)
   }, [
      cnamePicked,
      cidPicked,
      cafmPicked,
      cdoyPicked,
      cprofessionPicked,
      caddressPicked,
      caddressNumberPicked,
      cpostalPicked,
      ccityPicked,
   ])

   return (
      <div className='customer-search-container box'>
         <input className='customer-search' placeholder='Αναζήτηση Πελάτη' onChange={(e) => setQuery(e.target.value)} />
         {showSearch ? (
            <div className='customer-search-results'>
               {customers?.map((customer) => (
                  <p
                     className='customer-search-select'
                     key={customer._id}
                     onClick={() =>
                        findSingleuser(
                           customer._id,
                           customer.name,
                           customer.afm,
                           customer.doy,
                           customer.profession,
                           customer.address,
                           customer.addressNumber,
                           customer.postalCode,
                           customer.city
                        )
                     }
                  >
                     {customer.name} / {customer.afm}
                  </p>
               ))}
            </div>
         ) : (
            <></>
         )}
         <div className='picked-customer-info'>
            <div className='picked-customer-label-info'>
               <p className='picked-customer-title cp-name'>Όνομα: </p>
               <p className='picked-customer-data cp-name'>{cnamePicked}</p>
            </div>
            <div className='picked-customer-label-info'>
               <p className='picked-customer-title cp-name'>ΑΦΜ: </p>
               <p className='picked-customer-data cp-name'>{cafmPicked}</p>
            </div>
            <div className='picked-customer-label-info'>
               <p className='picked-customer-title cp-name'>Δ.Ο.Υ: </p>
               <p className='picked-customer-data cp-name'>{cdoyPicked}</p>
            </div>
            <div className='picked-customer-label-info'>
               <p className='picked-customer-title cp-name'>Επάγγελμα: </p>
               <p className='picked-customer-data cp-name'>{cprofessionPicked}</p>
            </div>
         </div>
      </div>
   )
}

export default CustomerSearch
