import { ReactComponent as BackArrow } from '../assets/icons/back-arrow.svg'
import { Link } from 'react-router-dom'

const BackButton = ({ url }) => {
   return (
      <Link to={url} className='btn-back'>
         <BackArrow fill='#fff' width='18px' height='18px' /> Πίσω
      </Link>
   )
}

export default BackButton
