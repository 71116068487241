import React from 'react'
import Footer from '../components/Footer'

function About() {
   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <p className='notfound-page-text'>AADE Τιμολόγιο Βασιλείου</p>
         </div>
         <Footer />
      </div>
   )
}

export default About
