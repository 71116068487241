export const displayPaymentType = (payment_type) => {
   if (payment_type === '1') return 'Επαγ. Λογαριασμός Πληρωμών Ημεδαπής'
   else if (payment_type === '2') return 'Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής'
   else if (payment_type === '3') return 'Μετρητά'
   else if (payment_type === '4') return 'Επιταγή'
   else if (payment_type === '5') return 'Επί Πιστώσει'
   else if (payment_type === '6') return 'Web Banking'
   else if (payment_type === '7') return 'POS / e-POS'
   else if (payment_type === 'Επαγ. Λογαριασμός Πληρωμών Ημεδαπής') return 'Επαγ. Λογαριασμός Πληρωμών Ημεδαπής'
   else if (payment_type === 'Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής') return 'Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής'
   else if (payment_type === 'Μετρητά') return 'Μετρητά'
   else if (payment_type === 'Επιταγή') return 'Επιταγή'
   else if (payment_type === 'Επί Πιστώσει') return 'Επί Πιστώσει'
   else if (payment_type === 'Web Banking') return 'Web Banking'
   else if (payment_type === 'POS / e-POS') return 'POS / e-POS'
}
