import React from 'react'
import { MdEuro } from 'react-icons/md'
import { twoDecimals } from '../utils/format'

function MonthlyTotals({ montlyInvoices, montlyInvoicesDynamo, montlyInvoicesSkroutz }) {
   const { totalIncome, totalNetValue, totalVatValue } = montlyInvoices
   const { totalIncomeDynamo, totalNetValueDynamo, totalVatValueDynamo } = montlyInvoicesDynamo
   const { totalIncomeSkroutz, totalNetValueSkroutz, totalVatValueSkroutz } = montlyInvoicesSkroutz

   return (
      <div className='monthly-stats'>
         <div className='stats-items'>
            <span className='stats-item-title'>Γενικό Συνολο</span>
            <span className='stats-item-total flex-center-0'>
               Τζίρος: {twoDecimals(totalIncome)}
               <MdEuro size={20} />
            </span>
            <span className='stats-item-net flex-center-0'>
               Καθαρό ποσό: {twoDecimals(totalNetValue)}
               <MdEuro size={16} />
            </span>
            <span className='stats-item-vat flex-center-0'>
               ΦΠΑ: {twoDecimals(totalVatValue)}
               <MdEuro size={16} />
            </span>
         </div>
         <div className='stats-items'>
            <span className='stats-item-title'>Σύνολο Dynamo</span>
            <span className='stats-item-total flex-center-0'>
               Τζίρος: {twoDecimals(totalIncomeDynamo)}
               <MdEuro size={20} />
            </span>
            <span className='stats-item-net flex-center-0'>
               Καθαρό ποσό: {twoDecimals(totalNetValueDynamo)}
               <MdEuro size={16} />
            </span>
            <span className='stats-item-vat flex-center-0'>
               ΦΠΑ: {twoDecimals(totalVatValueDynamo)}
               <MdEuro size={16} />
            </span>
         </div>
         <div className='stats-items'>
            <span className='stats-item-title'>Σύνολο Skroutz</span>
            <span className='stats-item-total flex-center-0'>
               Τζίρος: {twoDecimals(totalIncomeSkroutz)}
               <MdEuro size={20} />
            </span>
            <span className='stats-item-net flex-center-0'>
               Καθαρό ποσό: {twoDecimals(totalNetValueSkroutz)}
               <MdEuro size={16} />
            </span>
            <span className='stats-item-vat flex-center-0'>
               ΦΠΑ: {twoDecimals(totalVatValueSkroutz)}
               <MdEuro size={16} />
            </span>
         </div>
      </div>
   )
}

export default MonthlyTotals
