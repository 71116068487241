import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import skroutzService from './skroutzService'

const initialState = {
   singleOrder: {},
   orders: [],
   invoiceState: {},
   isLoadingSkroutz: false,
   isInvoiceLoading: false,
   postInvoiceLoading: false,
   payOnDeliverLoading: false,
}

// Get single skroutz order
export const getSkroutzOrder = createAsyncThunk('skroutz/order', async (orderId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.getSkroutzOrder(orderId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get all skroutz orders
export const getSkroutzOrders = createAsyncThunk('skroutz/orders', async (query, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.getSkroutzOrders(query, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Post skroutz order accept
export const postSkroutzAccept = createAsyncThunk('skroutz/acceptOrder', async ({ query, data }, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.postSkroutzAccept(query, data, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get skroutz order invoice state
export const getInvoiceState = createAsyncThunk('skroutz/getStateOfInvoice', async (orderId, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.getInvoiceState(orderId, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get skroutz order invoice state
export const updatePayOnDelivery = createAsyncThunk('skroutz/updatePayOnDelivery', async (data, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.updatePayOnDelivery(data, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Get skroutz order invoice state
export const updateOrderMark = createAsyncThunk('skroutz/updateOrderMark', async (data, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.updateOrderMark(data, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

// Post skroutz invoice
export const postSkroutzInvoice = createAsyncThunk('skroutz/postInvoice', async (invoiceData, thunkAPI) => {
   try {
      const token = thunkAPI.getState().auth.user.token
      return await skroutzService.postSkroutzInvoice(invoiceData, token)
   } catch (error) {
      const message =
         (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

      return thunkAPI.rejectWithValue(message)
   }
})

export const aadeSlice = createSlice({
   name: 'skroutz',
   initialState,
   reducers: {
      resetSkroutz: (state) => initialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(getSkroutzOrder.pending, (state) => {
            state.isLoadingSkroutz = true
         })
         .addCase(getSkroutzOrder.fulfilled, (state, action) => {
            state.isLoadingSkroutz = false
            state.singleOrder = action.payload
         })
         .addCase(getSkroutzOrder.rejected, (state) => {
            state.isLoadingSkroutz = false
         })
         .addCase(getSkroutzOrders.pending, (state) => {
            state.isLoadingSkroutz = true
         })
         .addCase(getSkroutzOrders.fulfilled, (state, action) => {
            state.isLoadingSkroutz = false
            state.orders = action.payload
         })
         .addCase(getSkroutzOrders.rejected, (state) => {
            state.isLoadingSkroutz = false
         })
         .addCase(getInvoiceState.pending, (state) => {
            state.isInvoiceLoading = true
         })
         .addCase(getInvoiceState.fulfilled, (state, action) => {
            state.isInvoiceLoading = false
            state.invoiceState = action.payload
         })
         .addCase(getInvoiceState.rejected, (state) => {
            state.isInvoiceLoading = false
         })
         .addCase(updatePayOnDelivery.pending, (state) => {
            state.payOnDeliverLoading = true
         })
         .addCase(updatePayOnDelivery.fulfilled, (state, action) => {
            state.payOnDeliverLoading = false
            state.invoiceState = action.payload
         })
         .addCase(updatePayOnDelivery.rejected, (state) => {
            state.payOnDeliverLoading = false
         })
         .addCase(updateOrderMark.pending, (state) => {
            state.payOnDeliverLoading = true
         })
         .addCase(updateOrderMark.fulfilled, (state, action) => {
            state.payOnDeliverLoading = false
            state.invoiceState = action.payload
         })
         .addCase(updateOrderMark.rejected, (state) => {
            state.payOnDeliverLoading = false
         })
         .addCase(postSkroutzInvoice.pending, (state) => {
            state.postInvoiceLoading = true
         })
         .addCase(postSkroutzInvoice.fulfilled, (state, action) => {
            state.postInvoiceLoading = false
            state.invoiceState = action.payload
         })
         .addCase(postSkroutzInvoice.rejected, (state) => {
            state.postInvoiceLoading = false
         })
   },
})

export default aadeSlice.reducer
export const { resetSkroutz } = aadeSlice.actions
