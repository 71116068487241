import { MdEuro } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { twoDecimals } from '../../utils/format';
import EastIcon from '@mui/icons-material/East';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useState } from 'react';

function MobileInvoicesList({
  invoices,
  currentPage,
  setCurrentPage,
  limit,
  setLimit,
}) {
  const navigate = useNavigate();

  // Pagination
  const [perPage, setPerPage] = useState(20);

  const indexOfLast = currentPage * limit;
  const indexOfFirst = indexOfLast - limit;
  const currentInvoices = invoices?.slice(indexOfFirst, indexOfLast);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(invoices?.length / limit); i++) {
    pageNumbers?.push(i);
  }

  const pageChange = (event, value) => {
    setCurrentPage(value);
    //setSearchParams({ pageNum: value })
  };

  return (
    <div className='display-invoices-table'>
      <div className='table-header'>
        <div>Μαρκ: </div>
        <div>Τύπος:</div>
        <div>Ημ/νία:</div>
        <div>Σειρά:</div>
        <div>Α/Α:</div>
        <div>ΑΦΜ:</div>
        <div>Καθ Αξία:</div>
        <div>Αξία ΦΠΑ:</div>
        <div>Σύνολο:</div>
        <div></div>
      </div>
      <div className='table-body'>
        {currentInvoices?.length > 0 ? (
          currentInvoices?.map((invoice) => (
            <div className='table-row' key={invoice?._id}>
              <div className='table-value'>
                <span className='title'>Μαρκ: </span>
                <span className={invoice.isVoid ? 'bg-red' : ''}>
                  {invoice?.mark}
                </span>
              </div>
              <div className='table-value'>
                <span className='title'>Τύπος: </span>
                {invoice?.type}
              </div>
              <div className='table-value'>
                <span className='title'>Ημερομηνία: </span>
                {invoice?.date}
              </div>

              <div className='table-value pc-only'>
                <span className='title'>Σειρά: </span>
                {invoice?.series}
              </div>
              <div className='table-value'>
                <span className='title'>Α/Α: </span>
                {invoice?.aa}
              </div>
              <div className='table-value'>
                <span className='title'>ΑΦΜ: </span>
                {invoice?.customerAFM}
              </div>
              <div className='table-value pc-only'>
                <span className='title'>Καθαρή Αξία: </span>
                {invoice?.totalPriceNet}
              </div>
              <div className='table-value pc-only'>
                <span className='title'>Αξία ΦΠΑ: </span>
                {invoice?.totalPriceVat}
              </div>
              <div className='table-value'>
                <span className='title'>Σύνολο: </span>
                <span>
                  {twoDecimals(invoice?.totalPrice)} <MdEuro size={14} />
                </span>
              </div>
              <button
                className='table-button'
                onClick={() => navigate(`/invoices/${invoice?.mark}`)}
              >
                Προβολή
              </button>
            </div>
          ))
        ) : (
          <div className='no-invoices'>Δεν υπάρχουν παραστατικά</div>
        )}
      </div>
      <div className='pagination-container'>
        <select defaultValue='25' onChange={(e) => setLimit(e.target.value)}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <Stack spacing={2} className='pagination-div'>
          <Pagination
            count={Math.ceil(invoices.length / perPage)}
            onChange={pageChange}
            variant='outlined'
            shape='rounded'
            className='pagination-list'
            page={currentPage}
          />
        </Stack>
      </div>
    </div>
  );
}

export default MobileInvoicesList;
