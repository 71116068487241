import { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getSingleUser, deleteUser, updateUser } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import toast from 'react-hot-toast'

function Customer() {
   const { singleUser, isLoading } = useSelector((state) => state.auth)

   const { name, email, isAdmin } = singleUser

   const [formData, setFormData] = useState({
      formName: name,
      formEail: email,
      password: '',
   })

   const { formName, formEail, password } = formData

   const onChange = (e) => {
      setFormData((prevState) => ({
         ...prevState,
         [e.target.name]: e.target.value,
      }))
   }

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { userId } = useParams()

   useEffect(() => {
      dispatch(getSingleUser(userId))
         .unwrap()
         .then(() => {})
         .catch(toast.error)
   }, [userId])

   const onDeleteUser = () => {
      dispatch(deleteUser(userId))
         .unwrap()
         .then(() => {
            toast.success('Επιτυχής διαγραφή χρήστη')
            navigate('/users')
         })
         .catch(toast.error)
   }

   //update User
   const onSubmit = (e) => {
      e.preventDefault()

      const userData = {
         name,
         email,
         password,
      }

      console.log(userData)

      if (password === '' && password.length < 6) {
         toast.error('Λάθος κωδικός')
      } else {
         dispatch(updateUser({ User: userId, Data: userData }))
            .unwrap()
            .then(() => {
               toast.success('Επιτυχής ενημέρωση χρήστη')
               navigate('/users')
            })
            .catch(toast.error)
      }
   }

   if (isLoading) {
      return <Spinner />
   }

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='customers-top-btns'>
               <BackButton url='/users' />
               <button className='mg-1-0 btn-delete' onClick={onDeleteUser}>
                  Διαγραφή
               </button>
            </div>

            <h2 className='page-title'>Στοιχεία Χρήστη</h2>
            <div className='box'>
               <div className='customer-info'>
                  <div className='min-width-120'>Όνομα</div>
                  <div className='customer-info'>{singleUser.name}</div>
               </div>
               <div className='customer-info'>
                  <div className='min-width-120'>Email</div>
                  <div className='customer-info'>{singleUser.email}</div>
               </div>
               <form onSubmit={onSubmit}>
                  {/* <div className='customer-info'>
                     <div className='min-width-120'>Νέος Κωδικός</div>
                     <div className='customer-info'>
                        <input
                           type='password'
                           className='create-user'
                           id='password'
                           name='password'
                           value={password}
                           onChange={onChange}
                        />
                     </div>
                  </div> */}
                  <div className='customer-info'>
                     <div className='min-width-120'>Admin</div>
                     <div className='customer-info'>{isAdmin ? 'Ναι' : 'Όχι'}</div>
                  </div>
                  {/* <div>
                     <button className='mg-1-0 btn-submit'>Ενημέρωση</button>
                  </div> */}
               </form>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Customer
