import axios from 'axios'

const API_URL = '/api/customer/'
const API_URL_FILTER = '/api/customer/?q='

// Create new customer
const createCustomer = async (customerData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.post(API_URL, customerData, config)

   return response.data
}

// Get all customers
const getCustomers = async (query, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL_FILTER + query, config)

   return response.data
}

// Get single customer
const getCustomer = async (afm, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   const response = await axios.get(API_URL + afm, config)

   return response.data
}

// Update single customer
const updateCustomer = async (customerId, customerData, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   console.log(customerId)

   const response = await axios.put(API_URL + customerId, customerData, config)

   return response.data
}

// Delete single customer
const deleteCustomer = async (customerId, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   }

   console.log(customerId)

   const response = await axios.delete(API_URL + customerId, config)

   return response.data
}

const customerService = { createCustomer, getCustomers, getCustomer, updateCustomer, deleteCustomer }

export default customerService
