import { useNavigate } from 'react-router'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

function MobileInvoicesList({ customers }) {
   const navigate = useNavigate()

   // Pagination
   const [perPage, setPerPage] = useState(20)
   const [currentPage, setCurrentPage] = useState(1)

   const indexOfLast = currentPage * perPage
   const indexOfFirst = indexOfLast - perPage
   const currentCustomers = customers?.slice(indexOfFirst, indexOfLast)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(customers?.length / perPage); i++) {
      pageNumbers?.push(i)
   }

   const pageChange = (event, value) => {
      setCurrentPage(value)
   }

   return (
      <div className='customers-table'>
         <div className='table-header'>
            <div>Όνομα:</div>
            <div>ΑΦΜ:</div>
            <div>Πόλη:</div>
            <div>Διεύθυνση:</div>
            <div>ΔΟΥ:</div>
            <div>Επάγγελμα:</div>
            <div></div>
         </div>
         <div className='table-body'>
            {currentCustomers?.length > 0 ? (
               currentCustomers?.map((customer) => (
                  <div className='row' key={customer?._id}>
                     <div className=''>
                        <span className='value-title'>Όνομα:</span> {customer?.name}
                     </div>
                     <div className=''>
                        <span className='value-title'>ΑΦΜ:</span> {customer?.afm}
                     </div>
                     <div className=''>
                        <span className='value-title'>Πόλη:</span> {customer?.city}
                     </div>
                     <div className=''>
                        <span className='value-title'>Διεύθυνση:</span> {customer?.address} {customer?.addressNumber}
                     </div>
                     <div className=''>
                        <span className='value-title'>ΔΟΥ:</span> {customer?.doy}
                     </div>
                     <div className=''>
                        <span className='value-title'>Επάγγελμα:</span> {customer?.profession}
                     </div>
                     <button className='table-button' onClick={() => navigate(`/customers/${customer?.afm}`)}>
                        Προβολή
                     </button>
                  </div>
               ))
            ) : (
               <div className='no-customers'>Δεν υπάρχουν πελάτες</div>
            )}
         </div>
         <div className='pagination-container'>
            <select defaultValue='25' onChange={(e) => setPerPage(e.target.value)}>
               <option value={10}>10</option>
               <option value={25}>25</option>
               <option value={50}>50</option>
               <option value={100}>100</option>
            </select>
            <Stack spacing={2} className='pagination-div'>
               <Pagination
                  count={Math.ceil(customers.length / perPage)}
                  onChange={pageChange}
                  variant='outlined'
                  shape='rounded'
                  className='pagination-list'
                  page={currentPage}
               />
            </Stack>
         </div>
      </div>
   )
}

export default MobileInvoicesList
