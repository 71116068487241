import { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getCustomer, updateCustomer } from '../features/customer/customerSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import toast from 'react-hot-toast'
import DeleteButton from '../components/DeleteButton'
import { FiEdit2 } from 'react-icons/fi'
import { GrFormClose } from 'react-icons/gr'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { FaFileInvoice } from 'react-icons/fa'
import { useRef } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { getInvoices } from '../features/invoice/invoiceSlice';
import { ReactComponent as Apodeixh } from '../assets/icons/apodeixh.svg';
import { ReactComponent as Timologio } from '../assets/icons/timologio.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { MdPictureAsPdf } from 'react-icons/md';
import MobileInvoicesList from '../components/MobileLists/MobileInvoicesList';
import * as rdrLocales from 'react-date-range/dist/locale';
import PrintInvoicesDialogs from '../components/PrintInvoicesDialogs';

function Customer() {
   let [searchParams, setSearchParams] = useSearchParams();

  // Get Invoices
  const { invoices } = useSelector((state) => state.invoice);
  const { customer, isLoadingCustomer } = useSelector((state) => state.customers)


  // Handle Open State
  const [openDate, setOpenDate] = useState(false);
  const [openDetailedInvoices, setOpenDetailedInvoices] = useState(false);
  const changeState = (popupState) => setOpenDetailedInvoices(popupState);

  // Set initials for search params
  const now = new Date();
  const [query, setQuery] = useState(
    searchParams?.get('query') ? parseInt(searchParams?.get('query')) : ''
  );
  const [currentPage, setCurrentPage] = useState(
    searchParams?.get('pageNum') ? parseInt(searchParams?.get('pageNum')) : 1
  );
  const [date, setDate] = useState([
    searchParams?.get('startDate') || searchParams?.get('endDate')
      ? {
          startDate: new Date(searchParams?.get('startDate')),
          endDate: new Date(searchParams?.get('endDate')),
          key: 'selection',
        }
      : {
          startDate: new Date(now.getFullYear() -30, now.getMonth()),
          endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
          key: 'selection',
        },
  ]);
  const [displayInvoiceType, setDisplayInvoiceType] = useState(
    searchParams?.get('invType') ? parseInt(searchParams?.get('invType')) : ''
  );

  const [limit, setLimit] = useState(
    searchParams?.get('limit') ? parseInt(searchParams?.get('limit')) : 25
  );

  const invoiceType = (e) => setDisplayInvoiceType(e.target.value);

  useEffect(() => {
    const params = `?query=${customer.afm}&pageNum=${currentPage}&startDate=${format(
      date[0]?.startDate,
      'yyyy-MM-dd'
    )}&endDate=${format(
      date[0]?.endDate,
      'yyyy-MM-dd'
    )}&invType=${displayInvoiceType}&limit=${limit}`;
    setSearchParams(params);

    dispatch(getInvoices(params));
    // eslint-disable-next-line
  }, [
    query,
    date[0]?.startDate,
    date[0]?.endDate,
    displayInvoiceType,
    currentPage,
  ]);

  //handle click away listener
  const detailedInvoicesRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!detailedInvoicesRef.current.contains(event.target)) {
        //setOpenDetailedInvoices(false)

        setOpenDate(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

   const [openPopup, setOpenPopup] = useState(false)

   const mystyle = {
      size: '26px',
      backgroundColor: 'red',
      fill: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
   }

   const [formData, setFormData] = useState({
      email: '',
      phone1: '',
      phone2: '',
   })

   const { email, phone1, phone2 } = formData

   const onChange = (e) => {
      setFormData((prevState) => ({
         ...prevState,
         [e.target.name]: e.target.value,
      }))
   }

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const params = useParams()
   const { afm } = useParams()

   useEffect(() => {
      dispatch(getCustomer(afm))
         .unwrap()
         .then(() => {})
         .catch(toast.error)
   }, [afm])

   const { _id } = customer
   const customerId = _id

   // update Customer
   const onSubmit = (e) => {
      e.preventDefault()

      const userData = {
         email,
         phone1,
         phone2,
      }

      dispatch(updateCustomer({ Customer: customerId, Data: userData }))
         .unwrap()
         .then(() => {
            setOpenPopup(false)
            toast.success('Επιτυχής ενημέρωση πελάτη')
            navigate('/customers')
         })
         .catch(toast.error)
   }

   //handle click away listener
   const popupRef = useRef()

   useEffect(() => {
      let handler = (event) => {
         if (!popupRef.current.contains(event.target)) {
            setOpenPopup(false)
         }
      }

      document.addEventListener('mousedown', handler)

      return () => {
         document.removeEventListener('mousedown', handler)
      }
   })

   if (isLoadingCustomer) {
      return <Spinner />
   }

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='customers-top-btns'>
               <BackButton url='/customers' />
               <DeleteButton customerId={customerId} />
            </div>

            <h2 className='page-title'>Στοιχεία Πελάτη</h2>
            <div className="customer-container">
               <div className='box'>
                  <div className='customer-info'>
                     <div className='min-width-120'>ΑΦΜ</div>
                     <div className='customer-info-data'>{customer.afm}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Όνομα</div>
                     <div className='customer-info-data'>{customer.name}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Επάγγελμα</div>
                     <div className='customer-info-data'>{customer.profession}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Διεύθυνση</div>
                     <div className='customer-info-data'>
                        {customer.address} {customer.addressNumber}
                     </div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Πόλη</div>
                     <div className='customer-info-data'>{customer.city}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Ταχ. Κώδικας</div>
                     <div className='customer-info-data'>{customer.postalCode}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>ΔΟΥ</div>
                     <div className='customer-info-data'>{customer.doy}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Email</div>
                     <div className='customer-info-data'>{customer.email}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Τηλέφωνο 1</div>
                     <div className='customer-info-data'>{customer.phone1}</div>
                  </div>
                  <div className='customer-info'>
                     <div className='min-width-120'>Τηλέφωνο 2</div>
                     <div className='customer-info-data'>{customer.phone2}</div>
                  </div>
                  <Dialog open={openPopup} presentation>
                     <div ref={popupRef}>
                        <DialogTitle>
                           <div className='dialog-header'>
                              <span>Ενημέρωση Πελάτη</span>
                              <GrFormClose size={26} style={mystyle} onClick={() => setOpenPopup(false)} />
                           </div>
                        </DialogTitle>
                        <DialogContent dividers>
                           <form onSubmit={onSubmit}>
                              <div className='customer-info'>
                                 <div className='min-width-120'>Email</div>
                                 <div className='customer-info-data'>
                                    <input
                                       type='email'
                                       className='create-user'
                                       id='email'
                                       name='email'
                                       value={email}
                                       onChange={onChange}
                                    />
                                 </div>
                              </div>
                              <div className='customer-info'>
                                 <div className='min-width-120'>Τηλέφωνο 1</div>
                                 <div className='customer-info-data'>
                                    <input
                                       type='number'
                                       className='create-user'
                                       id='phone1'
                                       name='phone1'
                                       value={phone1}
                                       onChange={onChange}
                                    />
                                 </div>
                              </div>
                              <div className='customer-info'>
                                 <div className='min-width-120'>Τηλέφωνο 2</div>
                                 <div className='customer-info-data'>
                                    <input
                                       type='number'
                                       className='create-user'
                                       id='phone2'
                                       name='phone2'
                                       value={phone2}
                                       onChange={onChange}
                                    />
                                 </div>
                              </div>
                              <div>
                                 <button className='mg-1-0 btn-submit'>Αποθήκευση</button>
                              </div>
                           </form>
                        </DialogContent>
                     </div>
                  </Dialog>
                  <div className='customers-btn-actions'>
                     <button className='mg-1-0 btn-update' onClick={() => setOpenPopup(true)}>
                        Ενημέρωση <FiEdit2 />
                     </button>
                     <button className='mg-1-0 btn-update' onClick={() => navigate(`/timologio?q=${afm}`)}>
                        Έκδοση Τιμολογίου <FaFileInvoice />
                     </button>
                  </div>
               </div>
               <div>
               <div className='box mg-top-15 flex-column-w100'>
          <div className='flex-center'>
            <div
              className='pos-relative calendar-input min-height-24'
              ref={detailedInvoicesRef}
            >
              <Calendar fill='#21293a' width='18px' height='18px' />
              <span
                onClick={() => {
                  setOpenDate(!openDate);
                }}
                className='display-date-range'
              >{`${format(date[0]?.startDate, 'dd/MM/yyyy')} έως ${format(
                date[0]?.endDate,
                'dd/MM/yyyy'
              )}`}</span>
              {openDate && (
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                  className='date'
                  locale={rdrLocales.el}
                />
              )}
            </div>
            <div>
              <select
                defaultValue='all'
                onChange={invoiceType}
                className='min-height-24 height-45'
                id='tropos_plhrwmhs'
              >
                <option value=''>Όλα</option>
                <option value='Απόδειξη Λιανικής Πώλησης'>Αποδείξεις</option>
                <option value='Τιμολόγιο Πώλησης'>Τιμολόγια</option>
                <option value='Προσφορά'>Προσφορές</option>
              </select>
            </div>
          </div>
        </div>
        <MobileInvoicesList
          invoices={invoices}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default Customer
